import { createContext, useContext, useEffect, useState } from "react";
import { collection, onSnapshot, query, where, orderBy } from "firebase/firestore";
import { db } from "../firebase-config";

export const AppContext = createContext();

export function useAppContext() {
  return useContext(AppContext);
}

const AppContextProvider = (props) => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [collectionOpen, setCollectionOpen] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  // Check online state
  useEffect(() => {
    const checkOnlineState = () => {
      setIsOnline((prev) => navigator.onLine ?? false);
    };
    window.addEventListener("online", checkOnlineState);
    window.addEventListener("offline", checkOnlineState);
    return () => {
      window.removeEventListener("online", checkOnlineState);
      window.removeEventListener("offline", checkOnlineState);
    };
  }, []);

  // Fetch about from firestore
  const [about, setAbout] = useState({ content: "", imageLink: "" });
  useEffect(() => {
    const colRef = collection(db, "about");
    const q = query(colRef);
    let returnData;
    const unsubcriber = onSnapshot(
      q,
      // { includeMetadataChanges: true },
      (snapshot) => {
        // Fetch data
        snapshot.docs.map((doc) => {
          return (returnData = { ...doc.data(), id: doc.id });
        });
        setAbout(returnData);
      }
    );
    return unsubcriber;
  }, []);

  // Fetch contact from firestore
  const [contact, setContact] = useState([]);
  useEffect(() => {
    const colRef = collection(db, "contact");
    const q = query(colRef);
    let returnData = [];
    const unsubcriber = onSnapshot(
      q,
      // { includeMetadataChanges: true },
      (snapshot) => {
        let newLinks = [];
        returnData = [];
        // Fetch data
        snapshot.docs.map((doc) => {
          return returnData.push({ ...doc.data() });
        });
        if (returnData && returnData.length > 0 && returnData[0].links) {
          const receivedLinks = { ...returnData[0].links };
          Object.keys(receivedLinks).map((item) => {
            if (receivedLinks[item] !== "") {
              return newLinks.push({
                platform: item,
                link: receivedLinks[item],
              });
            }
            return null;
          });
        }
        setContact((current) => [...newLinks]);
      }
    );
    return unsubcriber;
  }, []);

  // Shopping cart
  const [cart, setCart] = useState([]);
  const [cartOpen, setCartOpen] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);

  const handlePayment = (x) => {
    setPaymentProcessing(x || false);
  };
  // Check sale options
  const checkImageSaleOptions = (item) => {
    const chosenImage = images.find((image) => image.id === item.id);
    if (
      !(
        item &&
        item.option &&
        item.option.price &&
        item.option.paper &&
        item.option.size &&
        chosenImage &&
        chosenImage.forSale &&
        chosenImage.options &&
        chosenImage.options.length > 0
      )
    ) {
      return false;
    }
    // Check option exists
    const chosenOption = chosenImage.options.find(
      (imageOptions) =>
        imageOptions.size === item.option.size &&
        imageOptions.paper === item.option.paper &&
        imageOptions.price === item.option.price
    );
    if (!chosenOption) {
      return false;
    }
    return chosenImage;
  };

  const checkBookSale = (item) => {
    const chosenBook = books.find((book) => book.id === item.id);
    if (
      !(
        item &&
        item.id &&
        item.author &&
        item.price &&
        item.title &&
        item.imgLink &&
        chosenBook &&
        chosenBook.id &&
        chosenBook.author &&
        chosenBook.price &&
        chosenBook.title &&
        chosenBook.imgLink &&
        chosenBook.forSale &&
        chosenBook.visible &&
        item.id === chosenBook.id &&
        item.author === chosenBook.author &&
        item.price === chosenBook.price &&
        item.title === chosenBook.title &&
        item.imgLink === chosenBook.imgLink
      )
    ) {
      return false;
    } else {
      return chosenBook;
    }
  };

  // Toggle cart items
  const toggleCartItem = (item) => {
    if (!item.type || (item.type !== "book" && item.type !== "image")) {
      // No type set or type no accepted
      return false;
    }
    if (item.type === "image") {
      handleImageCartToggle(item);
    }
    if (item.type === "book") {
      handleBookCartToggle(item);
    }
  };

  const handleBookCartToggle = (item) => {
    if (!item) {
      return false;
    }
    const cartItem = {
      id: item.id,
      type: "book",
      imgLink: item.imgLink,
      title: item.title,
      price: item.price,
      author: item.author,
    };
    let cartArray = [];
    const cartBook = cart.find((cartItem) => cartItem.type === "book" && cartItem.id === item.id);
    if (!checkBookSale(cartItem)) {
      return false;
    }
    if (!cartBook) {
      // Book not in cart
      cartArray = [cartItem, ...cart];
    } else {
      // Book already in cart - filter out
      const filteredCart = cart.filter((cartItem) => cartItem.type !== "book" || cartItem.id !== item.id);
      cartArray = [...filteredCart];
    }
    // Update cart and local storage
    localStorage.setItem("cart", JSON.stringify([...cartArray]));
    setCart((prev) => {
      return [...cartArray];
    });
  };

  const handleImageCartToggle = (item) => {
    const canBuy = checkImageSaleOptions(item);
    if (!canBuy) {
      return false;
    }
    let cartArray = [];
    // Check if image in cart
    const cartImage = cart.find((cartItem) => cartItem.type === "image" && cartItem.id === item.id);
    if (!(cartImage && cartImage.options && cartImage.options.length > 0)) {
      // Image not in cart - add to cart with option details
      cartArray = [
        {
          id: item.id,
          type: "image",
          img: canBuy.thumbURL,
          name: canBuy.name,
          options: [
            {
              size: item.option.size,
              paper: item.option.paper,
              price: item.option.price,
            },
          ],
        },
        ...cart,
      ];
    } else {
      const filteredCart = cart.filter((cartItem) => cartItem.type !== "image" || cartItem.id !== item.id);
      // Image in cart - check if option in image options
      const cartOption = cartImage.options.find(
        (cartItemOption) =>
          cartItemOption.size === item.option.size &&
          cartItemOption.paper === item.option.paper &&
          cartItemOption.price === item.option.price
      );
      if (!cartOption) {
        // Option not in image options - add option to cart image
        const newCartImageOptions = [
          {
            price: item.option.price,
            size: item.option.size,
            paper: item.option.paper,
          },
          ...cartImage.options,
        ];
        cartArray = [{ ...cartImage, options: [...newCartImageOptions] }, ...filteredCart];
      } else {
        // Option in image options - remove option from cart image
        const newCartImageOptions = cartImage.options.filter(
          (cartItemOption) =>
            !(
              cartItemOption.size === item.option.size &&
              cartItemOption.paper === item.option.paper &&
              cartItemOption.price === item.option.price
            )
        );
        // Check if new cart options is greater than 0
        if (newCartImageOptions && newCartImageOptions.length > 0) {
          // Option still selected - maintain position in cart and keep in cart with updated options
          cartArray = [...cart];
          cartArray.forEach((changeItem) => {
            if (changeItem.id === item.id) {
              changeItem.options = [...newCartImageOptions];
            }
          });
        } else {
          // No option selected - remove image from cart
          cartArray = [...filteredCart];
        }
      }
    }
    // Update cart and local storage
    localStorage.setItem("cart", JSON.stringify([...cartArray]));
    setCart((prev) => {
      return [...cartArray];
    });
  };

  // Check cart when data changes
  const handleImageChange = (changes) => {
    setCart((prev) => {
      let localPrev = [...prev];
      // Check for modified images
      if (changes && changes.length > 0) {
        changes.forEach((modifiedItem) => {
          localPrev = localPrev.filter((cartItem) => cartItem.type === "book" || cartItem.id !== modifiedItem.id);
        });
      }
      // Return new cart
      localStorage.setItem("cart", JSON.stringify([...localPrev]));
      return [...localPrev];
    });
  };

  const handleBookChange = (changes) => {
    setCart((prev) => {
      let localPrev = [...prev];
      // Check for modified images
      if (changes && changes.length > 0) {
        changes.forEach((modifiedItem) => {
          localPrev = localPrev.filter((cartItem) => cartItem.type === "image" || cartItem.id !== modifiedItem.id);
        });
      }
      // Return new cart
      localStorage.setItem("cart", JSON.stringify([...localPrev]));
      return [...localPrev];
    });
  };

  // Empty cart
  const emptyCart = () => {
    // Clear local storage
    localStorage.removeItem("cart");
    // Clear cart
    setCart(() => []);
  };

  // Fetch galleries from firestore:
  const [collections, setCollections] = useState([]);
  useEffect(() => {
    const colRef = collection(db, "galleries");
    const q = query(colRef, where("visible", "==", true), orderBy("timestamp", "desc"));
    let returnData = [];
    const unsubcriber = onSnapshot(
      q,
      // { includeMetadataChanges: true },
      (snapshot) => {
        returnData = [];
        // Fetch data
        snapshot.docs.map((doc) => {
          return returnData.push({ ...doc.data(), id: doc.id });
        });
        setCollections(returnData);
      }
    );
    return unsubcriber;
  }, []);

  // Build navItems
  const [navItems, setNavItems] = useState([]);
  useEffect(() => {
    const groupArray = [];
    collections
      .filter((item) => item.collectionImages && item.collectionImages.length > 0)
      .map((collection) => {
        if (collection.group && collection.group !== "") {
          // Has sub
          if (!groupArray.find((item) => item.name === collection.group)) {
            return groupArray.push({
              name: collection.group,
              hasSub: true,
            });
          }
          return null;
        } else {
          return groupArray.push({
            name: collection.name,
            hasSub: false,
          });
        }
      });
    // Reorder items so portfolio and street come first
    const rebuiltGroups = [
      ...groupArray.filter((item) => item.name === "portfolio"),
      ...groupArray.filter((item) => item.name === "street"),
      ...groupArray.filter((item) => item.name === "lifescapes"),
      ...groupArray.filter((item) => item.name === "projects"),
      ...groupArray.filter((item) => item.name === "commissions"),
      ...groupArray.filter(
        (item) =>
          !(
            item.name === "portfolio" ||
            item.name === "street" ||
            item.name === "lifescapes" ||
            item.name === "projects" ||
            item.name === "commissions"
          )
      ),
    ];

    setNavItems((prev) => [...rebuiltGroups, { name: "book", hasSub: false }, { name: "about", hasSub: true }]);
  }, [collections]);

  // Fetch images from firestore
  const [images, setImages] = useState([]);
  useEffect(() => {
    const colRef = collection(db, "images");
    const q = query(colRef);
    const unsubcriber = onSnapshot(
      q,
      // { includeMetadataChanges: true },
      (snapshot) => {
        // Fetch data
        const returnData = [];
        snapshot.docs.map((doc) => {
          return returnData.push({ ...doc.data(), id: doc.id });
        });
        setImages(returnData);
        const changes = [];
        snapshot.docChanges().forEach((change) => {
          // Check change type and handle accordingly
          if (change.type === "modified" || change.type === "removed") {
            // Image modified
            changes.push({ ...change.doc.data(), id: change.doc.id });
          }
        });
        if (changes.length > 0 || changes.length > 0) {
          handleImageChange(changes);
        }
      }
    );
    return unsubcriber;
  }, []);

  // Fetch books from firestore
  const [books, setBooks] = useState([]);
  useEffect(() => {
    const colRef = collection(db, "books");
    const q = query(colRef, where("visible", "==", true), orderBy("timestamp", "desc"));
    const unsubcriber = onSnapshot(
      q,
      // { includeMetadataChanges: true },
      (snapshot) => {
        // Fetch data
        const returnData = [];
        snapshot.docs.map((doc) => {
          return returnData.push({ ...doc.data(), id: doc.id });
        });
        setBooks(returnData);
        const changes = [];
        snapshot.docChanges().forEach((change) => {
          // Check change type and handle accordingly
          if (change.type === "modified" || change.type === "removed") {
            // Image modified
            changes.push({ ...change.doc.data(), id: change.doc.id });
          }
        });
        if (changes.length > 0 || changes.length > 0) {
          // Handle book changes
          handleBookChange(changes);
        }
      }
    );
    return unsubcriber;
  }, []);

  // Loaded images - rebuild cart if needed
  useEffect(() => {
    const localStorageCart = window.localStorage.getItem("cart") ?? null;
    const newCart = [];
    if (!localStorageCart) {
      return;
    }
    const cartJSON = JSON.parse(localStorageCart);
    if (
      images &&
      images.length > 0 &&
      cartJSON &&
      cartJSON.length > 0 &&
      cartJSON.filter((cartItem) => cartItem.type === "image").length > 0
    ) {
      // Loop through local cart images
      const localCartImages = cartJSON.filter((cartItem) => cartItem.type === "image");
      const localCartBooks = cartJSON.filter((cartItem) => cartItem.type === "book");
      localCartImages.forEach((localCartItem) => {
        if (localCartItem.id && localCartItem.options && localCartItem.options.length > 0) {
          const newCartItem = { ...localCartItem, options: [] };
          // Loop through options and check sale requirements met
          localCartItem.options.forEach((localCartItemOption) => {
            const canBuy = checkImageSaleOptions({
              id: localCartItem.id,
              option: { ...localCartItemOption },
            });
            if (canBuy) {
              // Option accepted - append to newCartItem
              newCartItem.options.push({ ...localCartItemOption });
            }
          });
          // If newCartItem has accepted options then push to newCart array
          if (newCartItem.options && newCartItem.options.length > 0) {
            newCart.push({ ...newCartItem });
          }
        }
      });
      // If newCart array has content then update the cart
      setCart((prev) => {
        localStorage.setItem("cart", JSON.stringify([...newCart, ...localCartBooks]));
        return [...newCart, ...localCartBooks];
      });
    }
  }, [images]);

  // Loaded books - rebuild cart if needed
  useEffect(() => {
    const localStorageCart = window.localStorage.getItem("cart") ?? null;
    const newCart = [];
    if (!localStorageCart) {
      return;
    }
    const cartJSON = JSON.parse(localStorageCart);
    if (
      books &&
      books.length > 0 &&
      cartJSON &&
      cartJSON.length > 0 &&
      cartJSON.filter((cartItem) => cartItem.type === "book").length > 0
    ) {
      // Loop through local cart images
      const localCartImages = cartJSON.filter((cartItem) => cartItem.type === "image");
      const localCartBooks = cartJSON.filter((cartItem) => cartItem.type === "book");
      localCartBooks.forEach((localCartItem) => {
        const canBuy = checkBookSale({ ...localCartItem });
        if (canBuy) {
          newCart.push({ ...localCartItem });
        }
      });
      // If newCart array has content then update the cart
      setCart((prev) => {
        localStorage.setItem("cart", JSON.stringify([...localCartImages, ...newCart]));
        return [...localCartImages, ...newCart];
      });
    }
  }, [books]);

  const values = {
    selectedGroup,
    setSelectedGroup,
    selectedCollection,
    collectionOpen,
    setCollectionOpen,
    setSelectedCollection,
    about,
    contact,
    books,
    collections,
    navItems,
    images,
    cart,
    setCart,
    toggleCartItem,
    cartOpen,
    setCartOpen,
    emptyCart,
    paymentProcessing,
    handlePayment,
    isOnline,
  };

  return <AppContext.Provider value={values}>{props.children}</AppContext.Provider>;
};

export default AppContextProvider;
