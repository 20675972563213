import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import AppContextProvider from "./contexts/AppContext";
import NavigationContextProvider from "./contexts/NavigationContext";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Router>
    <Routes>
      <Route
        path={"*"}
        element={
          <AppContextProvider>
            <NavigationContextProvider>
              <App />
            </NavigationContextProvider>
          </AppContextProvider>
        }
      ></Route>
    </Routes>
  </Router>
  // </React.StrictMode>
);
