import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import useSize from "../../utils/useSize";

const HomeUnderlay = () => {
  const { pathname } = useLocation();
  const { width } = useSize();
  const [curIndex, setCurIndex] = useState(0);
  const [windowActive, setWindowActive] = useState(true);

  const sliderVariants = {
    initial: { x: "100vw" },
    animate: { x: 0, transition: { ease: "easeInOut", duration: 0.8 } },
    exit: { x: "-100vw", transition: { ease: "easeInOut", duration: 0.8 } },
  };

  const slides = [
    <motion.div
      key="one"
      variants={sliderVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      className="absolute inset-0 h-full w-full bg-homebackone bg-cover bg-no-repeat bg-bottom"
    />,
    <motion.div
      key="two"
      variants={sliderVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      className="absolute inset-0 h-full w-full bg-homebacktwo bg-cover bg-no-repeat bg-center"
    />,
    <motion.div
      key="three"
      variants={sliderVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      className="absolute inset-0 h-full w-full bg-homebackthree bg-cover bg-no-repeat bg-center"
    />,
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      // Paused for now - adding toggle in admin area to activate animation
      if (false && pathname === "/" && width >= 950 && windowActive) {
        setCurIndex((prev) => (prev < slides.length - 1 ? prev + 1 : 0));
      }
    }, 10000);
    return () => {
      clearInterval(timer);
    };
  }, [pathname, width, windowActive]);

  // Pause slider when page inactive
  useEffect(() => {
    const blurWindow = () => setWindowActive(false);
    const activeWindow = () => setWindowActive(true);
    window.addEventListener("blur", blurWindow);
    window.addEventListener("focus", activeWindow);
    return () => {
      window.removeEventListener("blur", blurWindow);
      window.removeEventListener("focus", activeWindow);
    };
  }, []);

  return (
    <div className="absolute h-full w-full inset-0">
      <AnimatePresence initial={false} wait>
        {slides[curIndex]}
      </AnimatePresence>
      {/* {pathname === "/" && width >= 950 && (
        <div className="opacity-0 absolute w-full h-full -left-full">
          <img src="/images/bg1.jpeg" alt="preloader" />
          <img src="/images/bg2.jpeg" alt="preloader" />
          <img src="/images/bg2.webp" alt="preloader" />
          <img src="/images/bg3.jpeg" alt="preloader" />
        </div>
      )} */}
    </div>
  );
};
export default HomeUnderlay;
