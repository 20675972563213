import { motion } from "framer-motion";
import { useEffect } from "react";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

import { useAppContext } from "../../contexts/AppContext";

import BuyButton from "../BuyButton";

const GalleryControls = ({ handleNext, handlePrev, showDetails, setShowDetails, selectedImage, cartOpen }) => {
  const { images, isOnline } = useAppContext();

  const buttonVariants = {
    hover: { opacity: 100, scale: 1.5 },
  };

  const handleKeyPress = (e) => {
    e = e || window.event;
    if (!cartOpen) {
      e.preventDefault();
      if (e.keyCode === 39) {
        handleNext();
      }
      if (e.keyCode === 37) {
        handlePrev();
      }
    }
  };

  const toggleDetails = () => {
    setShowDetails((prev) => !prev);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    const htmlElement = document.querySelector("html");
    htmlElement.classList.add("gallery");
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      htmlElement.classList.remove("gallery");
    };
  });

  return (
    <div className="shrink-0 px-[20px] break950:px-[60px] py-[8px] flex justify-end items-center">
      {images.find((item) => item.id === selectedImage.imageID && item.caption && item.caption !== "") && (
        <div className="flex-1 flex justify-start items-center overflow-hidden">
          <p className="shrink-0 grow-0 basis-full truncate text-[12px]">
            {images.find((item) => item.id === selectedImage.imageID).caption}
          </p>
        </div>
      )}
      {/* Buttons */}
      <div className="flex items-center justify-end translate-x-[6px] break950:hidden">
        {isOnline &&
          images.find((item) => item.id === selectedImage.imageID) &&
          images.find((item) => item.id === selectedImage.imageID && item.forSale) &&
          !showDetails && <BuyButton text="buy" action={toggleDetails} icon={faShoppingCart} type="secondary" />}
      </div>
      {/* Left & Right */}
      <div className="flex gap-0 break340:gap-2 translate-x-[12px] shrink-0">
        <motion.div
          variants={buttonVariants}
          whileTap="hover"
          whileHover="hover"
          className="cursor-pointer relative pb-[4px] px-2 break340:px-3 opacity-20 outline-none focus:outline-none"
          onClick={handlePrev}
        >
          <div className="flex justify-center items-center text-3xl scale-y-150 text-black leading-none">{"<"}</div>
        </motion.div>
        <motion.div
          variants={buttonVariants}
          whileTap="hover"
          whileHover="hover"
          className="cursor-pointer relative pb-[4px] px-2 break340:px-3 opacity-20 outline-none focus:outline-none"
          onClick={handleNext}
        >
          <div className="flex justify-center items-center text-3xl scale-y-150 text-black leading-none">{">"}</div>
        </motion.div>
      </div>
    </div>
  );
};

export default GalleryControls;
