const GalleryThumbnail = ({
  selectedImage,
  setSelectedImage,
  index,
  image,
  setShowDetails,
}) => {
  const handleThumbSelect = () => {
    setShowDetails(false);
    setSelectedImage({ imageIndex: index, imageID: image.id });
  };
  return (
    <div
      className="shrink-0 grow-0 cursor-pointer h-full"
      onClick={handleThumbSelect}
    >
      <img
        className={`w-auto h-[51px] rounded-sm ${
          selectedImage.imageIndex === index
            ? "border-[2px] border-green-400"
            : "border-[2px] border-transparent"
        }`}
        src={image.thumbURL ?? image.downloadURL}
        alt="thumbnail"
      />
    </div>
  );
};

export default GalleryThumbnail;
