import { useAppContext } from "../../contexts/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import Loader from "../Loader";

const AboutContact = () => {
  const { contact } = useAppContext();

  return (
    <div className="pt-[10px] px-[20px] break950:px-[60px]">
      <div className="w-full pt-[350px] relative">
        <div className="absolute inset-0">
          <img
            src="/images/alan2.jpeg"
            alt="contact-alan"
            className="h-full max-w-full rounded-sm"
          />
        </div>
      </div>
      <div className="pt-[10px] pb-[40px] flex flex-col">
        <span className="block text-[14px] break470:text-[16px] opacity-50">
          You'll usually find me in Battersea, London.
          <br />I work both in the UK and internationally.
        </span>
        {/* Links */}
        {(!contact || contact.length < 1) && (
          <Loader type="dark" width="100px" />
        )}

        <div className="flex flex-col gap-3 py-6 justify-start items-start">
          {contact &&
            contact.length > 0 &&
            contact.map((contactItem) => (
              <a
                key={contactItem.platform}
                href={
                  contactItem.platform === "email"
                    ? `mailto:${contactItem.link}`
                    : contactItem.platform === "phone"
                    ? `tel:${contactItem.link}`
                    : `${contactItem.link}`
                }
                rel="noreferrer"
                target="_blank"
              >
                <div className="w-[150px] px-[14px] py-[7px] rounded border-[1px] border-[rgba(0,0,0,.2)] bg-white flex justify-start items-center gap-3 group">
                  <FontAwesomeIcon
                    className="opacity-50 text-2xl group-hover:opacity-100 group-hover:text-accentColour"
                    icon={
                      contactItem.platform === "phone"
                        ? faPhone
                        : contactItem.platform === "email"
                        ? faEnvelope
                        : contactItem.platform === "instagram"
                        ? faInstagram
                        : contactItem.platform === "facebook"
                        ? faFacebook
                        : contactItem.platform === "youtube"
                        ? faYoutube
                        : faTwitter
                    }
                  />
                  <span className="capitalize">{contactItem.platform}</span>
                </div>
              </a>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AboutContact;
