import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BuyButton = ({ action, icon, text, type }) => {
  return (
    <div
      className={`rounded-sm flex justify-start items-center cursor-pointer ${
        type === "primary"
          ? "py-[6px] px-[12px] uppercase bg-accentColour text-white tracking-[1px] gap-2"
          : type === "secondary" && text === "buy"
          ? "py-[4px] px-[8px] text-[14px] uppercase bg-[rgba(0,0,0,.1)] font-semibold text-accentColour gap-2"
          : type === "secondary"
          ? "py-[3px] px-[8px] text-[14px] uppercase border-2 border-accentColour text-black gap-2"
          : "py-[6px] text-[14px] capitalize text-black gap-1"
      } `}
      onClick={action}
    >
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className={`${
            type === "primary"
              ? "text-white"
              : type === "secondary" && text === "buy"
              ? "text-accentColour text-[14px]"
              : type === "secondary"
              ? "text-accentColour text-[18px]"
              : "text-red-400 text-[18px]"
          }`}
        />
      )}
      {text}
    </div>
  );
};

export default BuyButton;
