import { useEffect, useState } from "react";

const useSize = () => {
  const [size, setSize] = useState([window.innerHeight, window.innerWidth]);

  useEffect(() => {
    const checkSize = () => {
      setSize((prev) => [window.innerHeight, window.innerWidth]);
    };
    window.addEventListener("resize", checkSize);
    return () => {
      window.removeEventListener("resize", checkSize);
    };
  }, []);
  return { height: size[0], width: size[1] };
};

export default useSize;
