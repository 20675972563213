import { useAppContext } from "../../contexts/AppContext";

import AboutBioLoader from "./AboutBioLoader";

const AboutBioInfo = () => {
  const { about } = useAppContext();

  return about && about.content && about.content !== "" ? (
    <section className="px-[20px] break950:px-[60px]">
      <div className="flex gap-6">
        <div className="flex-1">
          {about && about.content && (
            <p
              dangerouslySetInnerHTML={{
                __html: about.content.replace(/(?:\r\n|\r|\n)/g, "<br />"),
              }}
            />
          )}
        </div>
        <div className="hidden break550:block max-w-[33%]">
          {about && about.imageLink && (
            <div className="sticky top-[30px]">
              <img src={about.imageLink} className="rounded" alt="Biography" />
              <p className="text-right text-[10px] pt-1">
                Credit: Julian Hanford
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  ) : (
    <AboutBioLoader />
  );
};

export default AboutBioInfo;
