import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase-config";
import { motion } from "framer-motion";
import { useAppContext } from "../../contexts/AppContext";

import StepButtons from "./StepButtons";

const Summary = ({ setStep, currentStep, orderSubTotal, deliveryAddress, deliveryFee }) => {
  const { cart, emptyCart } = useAppContext();
  const paypalCreateOrder = httpsCallable(functions, "paypalCreateOrderV2");
  const paypalHandleOrder = httpsCallable(functions, "paypalHandleOrder");
  const paypalCleanUpOrder = httpsCallable(functions, "paypalCleanUpOrder");
  const [{ isPending }] = usePayPalScriptReducer();

  // Force popup paypal - rather than expanding PayPal card option
  const asyncFunc = async () => {
    await new Promise((resolve) => resolve());
  };

  const onCreateOrder = async () => {
    const orderObj = {
      items: cart,
      deliveryAddress: deliveryAddress,
    };
    return paypalCreateOrder(orderObj)
      .then((res) => {
        if (res.data === "Failed") {
          setStep(4);
          return null;
        } else {
          return res.data;
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
        return "no id";
      });
  };

  const onApproveOrder = async (data) => {
    return paypalHandleOrder({ orderID: data.orderID }).then((res) => {
      // Clear cart, show completed order message
      if (res.data.status === "COMPLETE") {
        emptyCart();
        setStep(() => 3);
      }
      if (res.data.status === "ERROR") {
        setStep(() => 4);
      }
    });
  };

  const onCleanupOrder = (data) => {
    return paypalCleanUpOrder({ orderID: data.orderID });
  };

  return (
    <>
      <div className="flex-1">
        <p className="text-2xl text-left text-black">Summary</p>
        <div className="py-6">
          <div className="flex flex-col bg-[rgba(0,0,0,0.07)] rounded b-shadow">
            <p className="flex justify-between items-center text-lg p-3">
              <span className="text-black">Order Amount</span>
              <span className="text-black">£{orderSubTotal}</span>
            </p>
            <p className="bg-[rgba(255,255,255,0.3)] flex justify-between items-center text-lg p-3">
              <span className="text-black">Delivery Fee</span>
              <span className="text-black">£{deliveryFee}</span>
            </p>
            <p className="flex justify-between items-center text-lg p-3 border-t-[1px] border-t-rgba(0,0,0,0.2)]">
              <span className="text-black font-semibold">Total</span>
              <span className="text-black font-semibold">£{orderSubTotal + deliveryFee}</span>
            </p>
          </div>
        </div>
        <div className="">
          {isPending ? (
            <div className="w-[740px] max-w-full flex flex-col justify-start items-stretch">
              <div className="w-full h-[55px] bg-rgba(0,0,0,0.2)] mb-[17px] relative overflow-hidden rounded">
                <motion.div
                  animate={{ scaleX: [0, 1, 1], x: ["0%", "0%", "100%"] }}
                  transition={{
                    repeat: Infinity,
                    ease: "easeInOut",
                    duration: 3,
                  }}
                  className="h-full w-full bg-[rgba(255,255,255,.6)] origin-left"
                />
              </div>
              <div className="w-full h-[55px] bg-rgba(0,0,0,0.2)] mb-[17px] relative overflow-hidden rounded">
                <motion.div
                  animate={{ scaleX: [0, 1, 1], x: ["0%", "0%", "100%"] }}
                  transition={{
                    repeat: Infinity,
                    ease: "easeInOut",
                    duration: 3,
                  }}
                  className="h-full w-full bg-[rgba(255,255,255,.6)] origin-left"
                />
              </div>
            </div>
          ) : null}
          <PayPalButtons
            onShippingChange={() => asyncFunc()}
            createOrder={() => onCreateOrder()}
            onApprove={(data) => onApproveOrder(data)}
            onError={(data) => onCleanupOrder(data)}
            onCancel={(data) => onCleanupOrder(data)}
            forceReRender={[cart]}
          />
        </div>
      </div>
      <StepButtons title="Payment" nextAction={() => null} setStep={setStep} currentStep={currentStep} />
    </>
  );
};

export default Summary;
