import { motion } from "framer-motion";

const Loader = ({ type, width, height, opacity }) => {
  return (
    <div
      className={`relative h-[4px] rounded-[1px] overflow-hidden ${
        type === "dark" ? "bg-[rgba(0,0,0,.3)]" : "bg-[rgba(255,255,255,.3)]"
      }`}
      style={{
        width: !width ? "100%" : width,
        height: !height ? "4px" : height,
        opacity: !opacity ? 1 : opacity,
      }}
    >
      <motion.div
        animate={{ scaleX: [0, 1, 1], x: ["0%", "0%", "100%"] }}
        transition={{
          repeat: Infinity,
          ease: "easeInOut",
          duration: 3,
        }}
        className="h-full w-full bg-[rgba(255,255,255,.6)] origin-left"
      ></motion.div>
    </div>
  );
};

export default Loader;
