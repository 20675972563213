import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import { useNavigationContext } from "../../contexts/NavigationContext";
import { useAppContext } from "../../contexts/AppContext";

import SideNavListItemSubList from "./SideNavItemSubList";

const SideNavListItem = ({ navItem }) => {
  const { pathname } = useLocation();
  const { collections } = useAppContext();
  const { activeNav, closeNav, selectNavItem } = useNavigationContext();
  const [itemActive, setItemActive] = useState(false);

  // Check to see if nav item is active
  useEffect(() => {
    let isActive = false;
    if (!navItem.hasSub) {
      // Top layer check
      if (
        (navItem.name === "book" && pathname === "/book") ||
        (navItem.name !== "book" &&
          pathname ===
            `/collections/${encodeURIComponent(navItem.name.toLowerCase())}`)
      ) {
        isActive = true;
      }
    } else {
      // Sub layer check
      if (
        navItem.name === "about" &&
        (pathname === "/biography" || pathname === "/contact")
      ) {
        // About pages
        isActive = true;
      } else {
        // Gallery pages
        if (collections) {
          const itemCollections = collections.filter(
            (collection) => collection.group === navItem.name
          );
          if (itemCollections && itemCollections.length > 0) {
            itemCollections.forEach((element) => {
              if (
                pathname ===
                `/collections/${encodeURIComponent(element.name.toLowerCase())}`
              ) {
                isActive = true;
              }
            });
          }
        }
      }
    }
    setItemActive((prev) => isActive);
  }, [pathname, activeNav, collections]);

  return (
    <li key={navItem.name} className={`relative pl-[20px] last:pb-6`}>
      {navItem.hasSub ? (
        <>
          <span
            className={`block relative pr-[44px] pl-[10px] cursor-pointer ${
              pathname === "/"
                ? "text-black break950:text-lightBackground rounded-[1px] break950:hover:bg-[rgba(255,255,255,.3)]"
                : ""
            } ${
              (!activeNav && itemActive) ||
              (activeNav && activeNav.name === navItem.name)
                ? "text-accentColour"
                : ""
            }`}
            onClick={
              activeNav && activeNav.name === navItem.name
                ? () => selectNavItem(null)
                : () => selectNavItem(navItem)
            }
          >
            {navItem.name}
            <div className="h-full w-[44px] flex justify-center items-center absolute right-0 inset-y-0">
              <FontAwesomeIcon
                className={`transition-all ${
                  (activeNav &&
                    activeNav.name &&
                    activeNav.name === navItem.name) ||
                  (!activeNav && itemActive)
                    ? "rotate-90"
                    : "rotate-0"
                }`}
                icon={faChevronRight}
              />
            </div>
          </span>
          <SideNavListItemSubList navItem={navItem} itemActive={itemActive} />
        </>
      ) : (
        <Link
          to={
            navItem.name === "book"
              ? "/book"
              : `/collections/${encodeURIComponent(navItem.name.toLowerCase())}`
          }
          className={`block pl-[10px] ${
            pathname === "/"
              ? "text-black break950:text-lightBackground rounded-[1px] break950:hover:bg-[rgba(255,255,255,.3)]"
              : ""
          } ${!activeNav && itemActive ? "text-accentColour" : ""}`}
          onClick={closeNav}
        >
          {navItem.name}
        </Link>
      )}

      {/* Selected */}
      {pathname !== "/" &&
        ((!activeNav && itemActive) ||
          (activeNav && activeNav.name === navItem.name)) && (
          <motion.div
            layoutId="miniNavLine"
            className="absolute w-[5px] h-[28px] left-[2px] top-0 bg-accentColour"
          />
        )}
    </li>
  );
};

export default SideNavListItem;
