import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Closer from "./utils/Closer";
import TopNav from "./components/nav/TopNav";
import NavUnderlay from "./components/nav/NavUnderlay";
import SideNav from "./components/nav/SideNav";
import HomeUnderlay from "./components/home/HomeUnderlay";
// import ShoppingCartModal from "./components/oldCart/ShoppingCartModal";
import ShoppingCartModal from "./components/checkout/CheckoutModal";
import CollectionPages from "./pages/CollectionPages";
import AboutPages from "./pages/AboutPages";
import NotificationsList from "./components/home/NotificationsList";

function App() {
  const location = useLocation();

  return (
    <div className={`fixed h-full w-full bg-white no_select flex flex-col justify-start items-stretch`}>
      <Closer />
      {/* Top Nav */}
      <TopNav />
      <div className="flex-1 flex flex-row items-stretch relative">
        <HomeUnderlay />
        <SideNav />
        <NavUnderlay />
        <div className="flex-1 relative">
          <NotificationsList />
          <AnimatePresence initial={false}>
            <ShoppingCartModal />
            <Routes location={location} key={location.pathname}>
              <Route exact path="/" element={<></>} />
              <Route path="biography" element={<AboutPages page="biography" />} />
              <Route path="book" element={<AboutPages page="book" />} />
              <Route path="contact" element={<AboutPages page="contact" />} />
              <Route path="/collections/:collectionName" element={<CollectionPages />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

export default App;
