import { motion, useScroll } from "framer-motion";
import { useRef } from "react";

import { pageVariants } from "../utils/framerVariants";

import PageHeader from "../components/PageHeader";
import AboutBio from "../components/biography/AboutBio";
import AboutContact from "../components/contact/AboutContact";
import AboutBooks from "../components/books/AboutBooks";

const AboutPages = ({ page }) => {
  const scrollContainer = useRef(null);
  const { scrollYProgress } = useScroll({ container: scrollContainer });

  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      className="absolute inset-0 flex flex-col bg-lightBackground z-50 overflow-hidden"
    >
      {page === "biography" && (
        <div className="bg-gray-200 w-full h-[5px] relative">
          <motion.div
            style={{ scaleX: scrollYProgress }}
            className="absolute inset-0 h-full w-full bg-accentColour origin-left"
          />
        </div>
      )}
      {/* Content */}
      <div className="flex-1 relative">
        <div
          ref={scrollContainer}
          className="absolute inset-0 overflow-y-auto overflow-x-hidden"
        >
          <PageHeader
            headerContent={{ name: page === "contact" ? "find me" : page }}
          />
          {page === "contact" && <AboutContact />}
          {page === "book" && <AboutBooks />}
          {page === "biography" && (
            <AboutBio scrollContainerRef={scrollContainer} />
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default AboutPages;
