import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppContext } from "../../contexts/AppContext";

const GalleryImageDetailsOption = ({ image, option }) => {
  const { cart, toggleCartItem } = useAppContext();

  const handleSelectToggle = () => {
    toggleCartItem({ id: image.id, option: option, type: "image" });
  };

  return (
    <div
      className={`flex rounded px-[10px] pt-[10px] pb-[6px] cursor-pointer ${
        cart.find((item) => item.type === "image" && item.id === image.id) &&
        cart.find((item) => item.id === image.id).options &&
        cart
          .find((item) => item.id === image.id)
          .options.find(
            (cartOption) =>
              cartOption.price === option.price &&
              cartOption.paper === option.paper &&
              cartOption.size === option.size
          )
          ? "border-[1px] border-green-400 bg-green-50"
          : "border-[1px] border-[rgba(0,0,0,.2)]"
      }`}
      onClick={handleSelectToggle}
    >
      <div className="basis-[30px]">
        <span
          className={`block h-[16px] w-[16px] border-[1px] rounded-full relative ${
            cart.find(
              (item) => item.type === "image" && item.id === image.id
            ) &&
            cart.find((item) => item.id === image.id).options &&
            cart
              .find((item) => item.id === image.id)
              .options.find(
                (cartOption) =>
                  cartOption.price === option.price &&
                  cartOption.paper === option.paper &&
                  cartOption.size === option.size
              )
              ? "border-green-400 bg-green-400"
              : "border-[rgba(0,0,0,.2)]"
          }`}
        >
          {cart.find((item) => item.type === "image" && item.id === image.id) &&
            cart.find((item) => item.id === image.id).options &&
            cart
              .find((item) => item.id === image.id)
              .options.find(
                (cartOption) =>
                  cartOption.price === option.price &&
                  cartOption.paper === option.paper &&
                  cartOption.size === option.size
              ) && (
              <FontAwesomeIcon
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-[12px]"
                icon={faCheck}
              />
            )}
        </span>
      </div>
      <div className="flex-1 flex flex-col gap-1">
        <h3 className="leading-none">{option.size}</h3>
        <span className="block opacity-50 text-xs leading-none">
          {option.paper}
        </span>
      </div>
      <h6 className="text-[22px]">£{option.price}</h6>
    </div>
  );
};

export default GalleryImageDetailsOption;
