import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";

import { useAppContext } from "../../contexts/AppContext";

import NotificationItem from "./NotificationItem";

const NotificationsList = () => {
  const [notifications, setNotifications] = useState([]);
  const { pathname } = useLocation();
  const { books } = useAppContext();
  const closedNotifications = useRef([]);

  const closeNotification = (e, x) => {
    e.stopPropagation();
    e.preventDefault();
    closedNotifications.current.push(x);
    setNotifications((prev) => prev.filter((item) => item.id !== x.id));
  };

  const addNotification = (item) => {
    return new Promise((resolve, reject) => {
      setNotifications((prev) => {
        // Check if notification in array already
        const foundItem = prev.some(
          (ni) => ni.type === item.type && ni.id === item.id
        );
        // Check if item was closed already
        const isClosed = closedNotifications.current.some(
          (clItem) => clItem.type === item.type && clItem.id === item.id
        );
        return foundItem || isClosed ? [...prev] : [...prev, { ...item }];
      });
      setTimeout(() => resolve(true), 500);
    });
  };

  useEffect(() => {
    // Checking books for promotions
    const buildInitialNotifications = async () => {
      if (books && books.length > 0 && pathname === "/") {
        // Show first book
        for (let index = 0; index < 1; index++) {
          const now = new Date();
          const then = new Date(books[index].timestamp.toDate());
          // Compare times - recent updates are less than 7 days
          const elapsedDays =
            (now.getTime() - then.getTime()) / (1000 * 60 * 60 * 24);
          if (elapsedDays <= 14 && books[index].forSale) {
            const bookNotifyObj = {};
            bookNotifyObj.id = "bookN" + books[index].id;
            bookNotifyObj.imgLink = books[index].imgLink;
            bookNotifyObj.title = books[index].title;
            bookNotifyObj.type = "book";
            bookNotifyObj.link = "/book";
            await addNotification(bookNotifyObj);
          }
        }
      }
    };
    const timer = setTimeout(() => {
      buildInitialNotifications();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [books, pathname]);

  return (
    <motion.div
      layout
      className="absolute bottom-0 right-0 w-full max-w-[320px] px-[20px] py-[20px] flex flex-col gap-3"
    >
      <AnimatePresence initial={false}>
        {notifications.map((notification) => (
          <NotificationItem
            key={notification.id}
            item={notification}
            closeNotification={(e) => closeNotification(e, notification)}
          />
        ))}
      </AnimatePresence>
    </motion.div>
  );
};

export default NotificationsList;
