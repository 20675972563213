import { motion } from "framer-motion";

const BooksLoader = () => {
  return (
    <div className="block h-[200px] w-full bg-[rgba(0,0,0,.1)] relative rounded">
      <motion.span
        animate={{ x: ["0vw", "100vw"] }}
        transition={{
          repeat: Infinity,
          ease: "easeInOut",
          duration: 3,
        }}
        className="absolute inset-y-0 -left-[100px] block w-[100px] h-full bg-gradient-to-r from-[rgba(255,255,255,0)] via-[rgba(255,255,255,.2)] to-[rgba(255,255,255,0)]"
      ></motion.span>
    </div>
  );
};

export default BooksLoader;
