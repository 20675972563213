import { motion } from "framer-motion";

const AboutBioAwardItem = ({ awardItem, pos, scale, opacity }) => {
  return (
    <motion.a
      className="basis-[350px] shrink grow-0"
      style={{ x: pos, scale: scale, opacity: opacity }}
      href={awardItem.link}
      rel="noreferrer"
      target="_blank"
    >
      <div className="relative w-full pt-[100%] break680:pt-[80%] break850:pt-[60%]">
        <div className="absolute inset-0 w-full h-full">
          <div className="bg-white h-shadow flex flex-col justify-center items-center h-full w-full rounded gap-[2px] break850:gap-3">
            <div className="h-1/3 flex gap-1 break550:gap-2 justify-center">
              {awardItem.images.map((image, index) => (
                <img
                  key={index}
                  className="h-full max-w-[80%]"
                  src={image.url}
                  alt={image.alt}
                />
              ))}
            </div>
            <div className="flex flex-col items-center">
              <p className="text-accentColour text-[18px] break680:text-[24px]">
                {awardItem.place}
              </p>
              <p className="leading-none text-[12px] break680:text-[16px]">
                {awardItem.year}
              </p>
            </div>
          </div>
        </div>
      </div>
    </motion.a>
  );
};

export default AboutBioAwardItem;
