const GalleryImageDetailsInfo = ({ image }) => {
  return (
    <div className="flex justify-start items-start flex-wrap gap-3">
      <img
        src={image.downloadURL}
        alt="Details"
        className="max-w-[100px] break470:max-w-[150px] max-h-[100px] rounded-sm"
      />
      <div className="flex-1 flex flex-col justify-start items-start gap-1">
        {image.name && (
          <h6 className="flex-1 text-[18px] break470:text-[18px] text-left capitalize">
            {image.name}
          </h6>
        )}
      </div>
    </div>
  );
};

export default GalleryImageDetailsInfo;
