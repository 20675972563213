import { useRef } from "react";
import { useScroll, useTransform } from "framer-motion";
import AboutBioAwardItem from "./AboutBioAwardItem";

const AboutBioAwards = ({ scrollContainerRef }) => {
  const scrollRef = useRef(null);
  const { scrollYProgress } = useScroll({
    layoutEffect: false,
    container: scrollContainerRef,
    target: scrollRef,
    offset: ["0 1", "1.5 1"],
  });

  const pos = useTransform(scrollYProgress, [0, 1], [100, 0]);
  const scale = useTransform(scrollYProgress, [0, 1], [0.75, 1]);
  const opacity = useTransform(scrollYProgress, [0, 1], [0, 1]);

  const awardItems = [
    {
      link: "https://streetphotographersfoundation.com/?s=alan+burles",
      place: "Finalist",
      year: "2022",
      images: [
        {
          url: "/images/streetphotographers.png",
          alt: "Streetphotographers",
        },
      ],
    },
    {
      link: "https://www.exibartstreet.com/news/alan-burles-best-series-finalist-of-2022-exibart-street-contest/",
      place: "Finalist",
      year: "2022",
      images: [
        {
          url: "/images/exibart.png",
          alt: "Exibart",
        },
      ],
    },
    {
      link: "https://streetphotographyinternational.com/2018-spi-street-awards",
      place: "Winner",
      year: "2018",
      images: [
        {
          url: "/images/leica.png",
          alt: "Leica",
        },
        {
          url: "/images/SPi.png",
          alt: "SPi",
        },
      ],
    },
  ];

  return (
    <section className="px-[20px] break950:px-[60px]">
      <h3 className="text-left text-2xl pb-6 break630:pb-9">AWARDS</h3>
      <div
        className="relative flex gap-3 justify-start items-center"
        ref={scrollRef}
      >
        {/* Content */}
        {awardItems.map((item, index) => (
          <AboutBioAwardItem
            key={index}
            awardItem={item}
            pos={pos}
            scale={scale}
            opacity={opacity}
          />
        ))}
      </div>
      <div className="text-left pt-8 flex flex-col gap-2 text-[10px] break470:text-[12px] break680:text-[16px]">
        <p>Association of Photographers Awards 2020, 2016</p>
        <p>PX3 (Prix de la Photographie, Paris), PX3 Official Selection 2011</p>
        <p>Finalist, Fuji Merit Exhibition, Bayeux Gallery, 2009</p>
        <p>PX3 (Prix de la Photographie, Paris), Merit 2009</p>
        <p>PX3 (Prix de la Photographie, Paris), 2nd place 2008</p>
        <p>Creative Review Photography Annual 2007 and 2006</p>
      </div>
    </section>
  );
};

export default AboutBioAwards;
