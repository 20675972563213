import AboutBioInterviewItem from "./AboutBioInterviewItem";

const AboutBioInterviews = ({ scrollContainerRef }) => {
  const interviews = [
    {
      name: "The Worldwide Wander",
      publication: "Street Wisdom",
      by: "David Pearl",
      date: "Fri 29 Sep, 2023",
      interviewLink: "https://www.youtube.com/watch?v=2N00eIReZE8",
    },
    {
      name: "An Interview With: Alan Burles",
      publication: "Luerzers Archive",
      by: "Hermann Vaske",
      date: "Mon 13 Jan, 2020",
      interviewLink: "https://www.luerzersarchive.com/interviews/alan-burles/",
    },
    {
      name: "Given Moments – A Conversation With Alan Burles",
      publication: "Sigg",
      by: "Daniel Sigg",
      date: "Thu 2 Sep, 2021",
      interviewLink: "https://danielsiggphotography.com/episode051/",
    },
    {
      name: "Spontaneous and unplanned fine art moments with street photographer Alan Burles",
      publication: "Prodibi",
      by: "Olivier Hamel",
      date: "Thu 4 Apr, 2019",
      interviewLink:
        "https://mag.prodibi.com/2019/04/04/spontaneous-and-unplanned-fine-art-moments-in-street-photography-with-alan-burles/",
    },
    {
      name: "Alan Burles – Listening With My Eyes",
      publication: "OC Society",
      by: "Vingle",
      date: "Fri 1 Mar, 2019",
      interviewLink:
        "https://www.ocsociety.org/contact/alan-burles-listening-with-my-eyes/",
    },
  ];
  return (
    <section className="px-[20px] break950:px-[60px]">
      <h3 className="text-left text-2xl pb-6 break630:pb-9 uppercase">
        Interviews
      </h3>
      <div className="flex flex-wrap divide-y-2">
        {interviews.map((item, index) => (
          <AboutBioInterviewItem
            key={index}
            interview={item}
            scrollContainerRef={scrollContainerRef}
          />
        ))}
      </div>
    </section>
  );
};

export default AboutBioInterviews;
