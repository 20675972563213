import { AnimatePresence } from "framer-motion";
import { useAppContext } from "../../contexts/AppContext";

import CartToggle from "./CartToggle";
import Checkout from "./Checkout";

const CheckoutModal = () => {
  const { cart, cartOpen, setCartOpen, isOnline } = useAppContext();

  return (
    <>
      {cartOpen && (
        <div
          className="bg-[rgba(0,0,0,.8)] fixed inset-0 h-full w-full z-[9996]"
          onClick={() => setCartOpen(false)}
        ></div>
      )}
      <AnimatePresence>
        {cart.length > 0 && isOnline && <CartToggle key="cartToggle" />}
        {cartOpen && <Checkout key="shoppingCart" />}
      </AnimatePresence>
    </>
  );
};

export default CheckoutModal;
