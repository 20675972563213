import { useLocation } from "react-router-dom";

const PageHeader = ({ headerContent }) => {
  const { pathname } = useLocation();

  return (
    <div
      className={`min-h-[72px] flex justify-center items-center gap-[8px] pl-[20px] break950:pl-[60px] pt-[20px] pb-3 ${
        pathname.includes("collections") ? "break950:hidden" : ""
      }`}
    >
      {/* Image  */}
      {headerContent.imgLink && (
        <div className="h-[40px] w-[40px] relative rounded-full overflow-hidden g-shadow">
          <img
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full max-w-none "
            src={headerContent.imgLink}
            alt={headerContent.name}
          />
        </div>
      )}
      {/* Title */}
      <h3
        className={`flex-1 text-black uppercase tracking-[1px] font-semibold truncate ${
          !headerContent.imgLink ? "text-2xl" : "text-[20px]"
        }`}
      >
        {headerContent.name}
      </h3>
    </div>
  );
};

export default PageHeader;
