import { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

const AboutBioInterviewItem = ({ interview, scrollContainerRef }) => {
  const scrollRef = useRef(null);
  const { scrollYProgress } = useScroll({
    layoutEffect: false,
    container: scrollContainerRef,
    target: scrollRef,
    offset: ["0 1", "1.5 1"],
  });

  const pos = useTransform(scrollYProgress, [0, 1], [100, 0]);
  const opacity = useTransform(scrollYProgress, [0, 1], [0, 1]);

  return (
    <a
      href={interview.interviewLink}
      target="_blank"
      rel="noreferrer"
      ref={scrollRef}
      className="basis-full flex flex-wrap justify-center"
    >
      {/* Text section */}
      <motion.div
        className="flex-1 py-3 pl-3 pr-6 flex flex-col"
        style={{ opacity: opacity, x: pos }}
      >
        <h3 className="text-[14px] break550:text-[18px]">{interview.name}</h3>
        <div className="bg-accentColour h-[3px] w-[50px] mt-[4px] mb-[10px] rounded-[1px]"></div>
        <span className="text-[12px] break550:text-base font-semibold">
          {interview.publication}
        </span>
        <p className="text-[10px] break550:text-[12px]">By {interview.by}</p>
      </motion.div>
    </a>
  );
};

export default AboutBioInterviewItem;
