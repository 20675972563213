import { motion } from "framer-motion";

import { useAppContext } from "../../contexts/AppContext";

import StepButtons from "./StepButtons";

const CheckCart = ({ setStep, currentStep }) => {
  const { cart } = useAppContext();

  const handleNextStep = () => {
    setStep(() => 1);
  };

  return (
    <>
      <div className="flex-1">
        <p className="text-2xl text-left text-black">Your Order</p>
        <div className="flex flex-col gap-2 justify-start items-stretch py-6">
          {cart &&
            cart.length > 0 &&
            cart.map((cartItem) =>
              cartItem.type === "image" ? (
                <ImageItem key={`image-${cartItem.id}`} item={cartItem} />
              ) : (
                <BookItem key={`book-${cartItem.id}`} item={cartItem} />
              )
            )}
        </div>
      </div>
      {cart.length > 0 && (
        <StepButtons setStep={setStep} title="Delivery" nextAction={handleNextStep} currentStep={currentStep} />
      )}
    </>
  );
};

const ImageItem = ({ item }) => {
  return (
    <motion.div className="flex flex-col rounded justify-start items-stretch bg-white b-shadow p-3">
      <div className="flex gap-2 items-center pb-3">
        <img className="max-w-[60px] rounded-sm" src={item.img} alt={item.name} />
        <h3 className="flex-1 capitalize">{item.name}</h3>
      </div>
      <span className="h-[1px] w-full bg-black opacity-10 rounded"></span>
      <div className="flex flex-col">
        {item.options.map((option) => (
          <ItemOption option={option} id={item.id} key={`${option.size + "_" + option.paper}`} />
        ))}
      </div>
    </motion.div>
  );
};

const BookItem = ({ item }) => {
  const { toggleCartItem } = useAppContext();

  const handleDelete = () => {
    toggleCartItem({ ...item });
  };

  return (
    <motion.div className="flex flex-col rounded justify-start items-stretch bg-white b-shadow p-3">
      <div className="flex gap-2 items-center pb-3">
        <img className="max-w-[60px] rounded-sm" src={item.imgLink} alt={item.title} />
        <h3 className="flex-1 capitalize">{item.title}</h3>
      </div>
      <span className="h-[1px] w-full bg-black opacity-10 rounded"></span>
      <div className="flex flex-col">
        <div className="flex justify-center items-start border-b-[1px] last:border-b-0 border-b-[rgba(0,0,0,0.1)] gap-2 py-2">
          <div className="flex-1 flex flex-col gap-1">
            <div>
              <p className="text-[10px] leading-none">Author</p>
              <span className="text-xs block">{item.author}</span>
            </div>
          </div>
          <div className="flex flex-col items-end justify-end">
            <h3 className="text-xl leading-none">£{item.price}</h3>
            <span onClick={handleDelete} className="text-[12px] text-red-400 cursor-pointer">
              Delete
            </span>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const ItemOption = ({ option, id }) => {
  const { toggleCartItem } = useAppContext();

  const handleDelete = () => {
    toggleCartItem({
      id: id,
      option: { size: option.size, price: option.price, paper: option.paper },
      type: "image",
    });
  };

  return (
    <div className="flex justify-center items-end border-b-[1px] last:border-b-0 border-b-[rgba(0,0,0,0.1)] gap-2 py-2">
      <div className="flex-1 flex flex-col gap-1">
        <div>
          <p className="text-[10px] leading-none">Size</p>
          <span className="text-xs block">{option.size}</span>
        </div>
        <div>
          <p className="text-[10px] leading-none">Printed On</p>
          <span className="text-xs block">{option.paper}</span>
        </div>
      </div>
      <div className="flex flex-col items-end justify-end">
        <h3 className="text-xl leading-none">£{option.price}</h3>
        <span onClick={handleDelete} className="text-[12px] text-red-400 cursor-pointer">
          Delete
        </span>
      </div>
    </div>
  );
};

export default CheckCart;
