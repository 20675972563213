import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

import { useNavigationContext } from "../../contexts/NavigationContext";
import useSize from "../../utils/useSize";
import { spring } from "../../utils/framerVariants";

import SideNavHeader from "./SideNavHeader";
import SideNavList from "./SideNavList";

const SideNav = () => {
  const { pathname } = useLocation();
  const { navOpen, selectNavItem } = useNavigationContext();
  const { width } = useSize();

  const variants = {
    initial: {
      x: width < 950 ? "-100%" : 0,
      transition: spring.fast,
    },
    animate: {
      x: navOpen ? 0 : width < 950 ? "-100%" : 0,
      transition: spring,
    },
  };

  return (
    <motion.nav
      className={`fixed break950:relative inset-y-0 h-full left-0 w-[400px] max-w-[100%] ${
        pathname === "/"
          ? "bg-lightBackground break950:bg-transparent border-r-[1px] break950:border-r-0 border-r-white break950:w-[300px]"
          : "bg-lightBackground border-r-[1px] border-r-white break950:w-[250px]"
      } z-[61] flex flex-col items-stretch justify-start`}
      variants={variants}
      initial="initial"
      animate="animate"
      onMouseLeave={() => selectNavItem(null)}
    >
      <SideNavHeader />
      <SideNavList />
    </motion.nav>
  );
};

export default SideNav;
