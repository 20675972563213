import { Link, useLocation } from "react-router-dom";

import { useNavigationContext } from "../../contexts/NavigationContext";

const SideNavHeader = () => {
  const { closeNav } = useNavigationContext();
  const { pathname } = useLocation();

  return (
    <div
      className={`flex justify-between items-center basis-[50px] break950:basis-[80px] shrink-0 px-[20px]`}
    >
      <h1 className={`flex-1`}>
        <Link
          to="/"
          className={`inline-block uppercase tracking-[1px] font-semibold text-[20px] break950:text-3xl ${
            pathname === "/"
              ? "text-black break950:text-lightBackground"
              : "text-black"
          }`}
          onClick={closeNav}
        >
          Alan Burles
        </Link>
      </h1>
      <div
        className="relative h-[30px] w-[24px] basis-[24px] shrink-0 cursor-pointer block break950:hidden"
        onClick={closeNav}
      >
        <span className="absolute top-1/2 -translate-y-1/2 rotate-45 bg-black h-[3px] w-full rounded-[1px]"></span>
        <span className="absolute top-1/2 -translate-y-1/2 -rotate-45 bg-black h-[3px] w-full rounded-[1px] "></span>
      </div>
    </div>
  );
};

export default SideNavHeader;
