import { useAppContext } from "../../contexts/AppContext";

import AboutBookItem from "./AboutBookItem";
import BooksLoader from "./BooksLoader";

const AboutBooks = () => {
  const { books } = useAppContext();

  return (
    <div className="pt-[10px] pb-[40px] break680:pb-[90px] px-[20px] break950:px-[60px] flex flex-col gap-4">
      {books &&
        books.length > 0 &&
        books.map((book) => <AboutBookItem key={book.id} book={book} />)}
      {(!books || books.length < 1) && <BooksLoader />}
    </div>
  );
};

export default AboutBooks;
