import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { useAppContext } from "../contexts/AppContext";
import { useNavigationContext } from "../contexts/NavigationContext";
import { pageVariants } from "../utils/framerVariants";

import PageHeader from "../components/PageHeader";
import Gallery from "../components/gallery/Gallery";

const GalleryPages = () => {
  const { collections, images } = useAppContext();
  const { setCollectionCaption } = useNavigationContext();
  const { collectionName } = useParams();
  const [localCollection, setLocalCollection] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (collections && collections.length > 0 && images && images.length > 0) {
      const found = collections.find(
        (item) =>
          item.name.toLowerCase() === collectionName &&
          item.collectionImages &&
          item.collectionImages.length > 0
      );
      if (found) {
        setLocalCollection((prev) => {
          return { ...found };
        });
        if (found.caption && found.caption !== "") {
          setCollectionCaption((prev) => found.caption);
        }
      } else {
        // Redirect to collections if not found
        navigate("/collections");
      }
    }
  }, [collectionName, collections, images]);

  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      className="absolute inset-0 flex flex-col bg-lightBackground z-50 overflow-hidden"
    >
      {localCollection && (
        <>
          <PageHeader headerContent={localCollection} />
          <Gallery collection={localCollection} />
        </>
      )}
    </motion.div>
  );
};

export default GalleryPages;
