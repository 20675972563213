import { motion } from "framer-motion";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";

import { useAppContext } from "../../contexts/AppContext";
import { spring } from "../../utils/framerVariants";

import GalleryImageDetailsOption from "./GalleryImageDetailsOption";
import GalleryImageDetailsInfo from "./GalleryImageDetailsInfo";
import BuyButton from "../BuyButton";

const GalleryImageDetails = ({ image, setShowDetails }) => {
  const { cart, cartOpen, setCartOpen, isOnline } = useAppContext();

  const detailsVariants = {
    initial: { scale: 0.9, y: "-100vh" },
    animate: {
      scale: [0.9, 0.9, 1],
      y: ["-100vh", "0vh", "0vh"],
      transition: { ease: "easeInOut", duration: 0.6 },
    },
    exit: {
      scale: [1, 0.9, 0.9],
      y: ["0vh", "0vh", "-100vh"],
      transition: { ease: "easeInOut", duration: 0.6 },
    },
  };

  const handleCheckout = () => {
    setCartOpen(true);
  };

  useEffect(() => {
    if (cartOpen || !isOnline) {
      setShowDetails(false);
    }
  }, [cartOpen, isOnline]);

  return (
    <motion.div
      key={"details"}
      variants={detailsVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      className="bg-white rounded max-h-full w-full max-w-[500px] overflow-y-auto g-shadow"
    >
      <div className="flex flex-col gap-3 p-3">
        <GalleryImageDetailsInfo image={image} />
        {image.forSale && image.options && image.options.length > 0 && (
          <>
            <span className="h-[1px] w-full bg-black opacity-10 rounded"></span>
            <div>
              <div className="flex flex-col gap-3">
                {image.options.map((item, index) => (
                  <GalleryImageDetailsOption
                    key={index}
                    image={image}
                    option={item}
                  />
                ))}
              </div>
            </div>
          </>
        )}
        {/* Checkout / Cancel */}
        <div
          className={`flex flex-wrap items-center gap-3 tracking-[1px] py-2 ${
            isOnline &&
            cart.length > 0 &&
            cart.filter(
              (cartItem) =>
                cartItem.id &&
                cartItem.type === "image" &&
                cartItem.id === image.id
            ).length > 0
              ? "justify-end"
              : "justify-center"
          }`}
        >
          <motion.span
            layout
            transition={spring}
            className={`uppercase cursor-pointer py-[6px] ${
              isOnline && cart.length > 0 ? "px-[12px]" : ""
            }`}
            onClick={() => setShowDetails(false)}
          >
            Close
          </motion.span>
          {isOnline &&
            cart.length > 0 &&
            cart.filter(
              (cartItem) =>
                cartItem.id &&
                cartItem.type === "image" &&
                cartItem.id === image.id
            ).length > 0 && (
              <BuyButton
                text="Checkout"
                type="primary"
                icon={faShoppingCart}
                action={handleCheckout}
              />
            )}
        </div>
      </div>
    </motion.div>
  );
};

export default GalleryImageDetails;
