import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../contexts/AppContext";
import { useNavigationContext } from "../contexts/NavigationContext";

const Closer = () => {
  const { setCartOpen } = useAppContext();
  const { closeNav, setCollectionCaption } = useNavigationContext();

  const location = useLocation();

  useEffect(() => {
    // Ensure cart closed
    setCartOpen(false);
    setCollectionCaption(null);
    // On Page resize close navs
    const closer = () => closeNav();

    window.removeEventListener("resize", closer);
    window.addEventListener("resize", closer);
    closer();
    return () => {
      window.removeEventListener("resize", closer);
    };
  }, [location.pathname]);

  return null;
};

export default Closer;
