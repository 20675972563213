import AboutBioAwards from "./AboutBioAwards";
import AboutBioFeatures from "./AboutBioFeatures";
import AboutBioInterviews from "./AboutBioInterviews";
import AboutBioInfo from "./AboutBioInfo";

const AboutBio = ({ scrollContainerRef }) => {
  return (
    <div>
      <div className="pb-[70px] break680:pb-[70px] flex flex-col gap-[60px] break680:gap-[70px]">
        <AboutBioInfo />
        <AboutBioAwards scrollContainerRef={scrollContainerRef} />
        <AboutBioFeatures scrollContainerRef={scrollContainerRef} />
        <AboutBioInterviews scrollContainerRef={scrollContainerRef} />
      </div>
    </div>
  );
};

export default AboutBio;
