import { useLocation } from "react-router-dom";

import { useAppContext } from "../../contexts/AppContext";
import { useNavigationContext } from "../../contexts/NavigationContext";
import useSize from "../../utils/useSize";

import SideNavListItem from "./SideNavListItem";
import SideNavCartToggle from "./SideNavCartToggle";

const SideNavList = () => {
  const { pathname } = useLocation();
  const { cart, isOnline, navItems } = useAppContext();
  const { collectionCaption } = useNavigationContext();
  const { width } = useSize();

  return (
    <div className="flex-1 relative w-full">
      <div className="absolute inset-0 overflow-x-hidden overflow-y-auto">
        <ul
          className={`w-full flex flex-col justify-start items-stretch gap-3 text-black uppercase tracking-wide font-thin py-2 ${
            pathname === "/" ? "break950:w-[250px]" : ""
          }`}
        >
          {navItems.map((navItem) => (
            <SideNavListItem key={navItem.name} navItem={navItem} />
          ))}
          {isOnline && cart.length > 0 && width >= 950 && <SideNavCartToggle />}
        </ul>
        {collectionCaption && collectionCaption !== "" && (
          <div className="px-[30px]">
            <p
              className="pt-[20px] pb-3 border-t-[1px] border-t-white text-[12px]"
              dangerouslySetInnerHTML={{
                __html: collectionCaption.replace(/(?:\r\n|\r|\n)/g, "<br />"),
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SideNavList;
