import AboutBioFeatureItem from "./AboutBioFeatureItem";

const AboutBioFeatures = ({ scrollContainerRef }) => {
  const features = [
    {
      name: "Comic Relief’s outlandish origin story – inside Nether Wallop festival",
      publication: "The Guardian",
      date: "Fri 17 Mar 2023",
      imgPath: "/images/feature1.png",
      featureLink:
        "https://www.theguardian.com/tv-and-radio/2023/mar/17/comic-relief-nether-wallop-festival",
    },
    {
      name: "A lighter side of life – picture essay",
      publication: "The Guardian",
      date: "Mon 26 Dec 2022",
      imgPath: "/images/feature2.png",
      featureLink:
        "https://www.theguardian.com/artanddesign/2022/dec/26/a-lighter-side-of-life-picture-essay",
    },
    {
      name: "Alan Burles: master of illusion – in pictures",
      publication: "The Guardian",
      date: "Thu 29 Nov 2018",
      imgPath: "/images/feature3.png",
      featureLink:
        "https://www.theguardian.com/artanddesign/gallery/2018/nov/29/alan-burles-master-of-illusion-in-pictures",
    },
    {
      name: "Surreal scenes and broken dreams: 2021’s best street photography – in pictures",
      publication: "The Guardian",
      date: "Tue 28 Sep 2021",
      imgPath: "/images/feature4.png",
      featureLink:
        "https://www.theguardian.com/artanddesign/gallery/2021/sep/28/surreal-scenes-and-broken-dreams-2021s-best-street-photography-in-pictures",
    },
    {
      name: "Was für ein gutes Auge, Herr Burles!",
      publication: "Der Spiegel",
      date: "Sun 26 May 2019",
      imgPath: "/images/feature5.png",
      featureLink:
        "https://www.spiegel.de/panorama/gesellschaft/street-photography-von-alan-burles-wenn-der-doenerladen-zum-fotomotiv-wird-a-1261225.html?sara_ref=re-xx-cp-sh",
    },
  ];
  return (
    <section className="px-[20px] break950:px-[60px]">
      <h3 className="text-left text-2xl pb-6 break630:pb-9">PRESS</h3>
      <div className="flex flex-wrap gap-6">
        {features.map((item, index) => (
          <AboutBioFeatureItem
            key={index}
            feature={item}
            scrollContainerRef={scrollContainerRef}
          />
        ))}
      </div>
    </section>
  );
};

export default AboutBioFeatures;
