import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";

const GalleryImagePhoto = ({ image, handleNext, handlePrev }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const slidingImage = useRef();

  const imageVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: [0, 1],
      transition: { ease: "easeInOut", duration: 0.2 },
    },
    exit: {
      scale: [1, 0.9, 0.9],
      y: ["0vh", "0vh", "100vh"],
      transition: { ease: "easeInOut", duration: 0.6 },
    },
  };

  const slider = () => {
    let initialX = null;
    let currentX = null;
    let deltaX = null;
    let initialY = null;
    let currentY = null;
    let deltaY = null;
    const threshold = 50;

    return {
      start: (e) => {
        initialX = e.touches[0].pageX;
        initialY = e.touches[0].pageY;
      },
      move: (e) => {
        if (!initialX) {
          initialX = e.touches[0].pageX;
        }
        if (!initialY) {
          initialY = e.touches[0].pageY;
        }
        currentX = e.touches[0].pageX;
        currentY = e.touches[0].pageY;
        deltaX = (currentX - initialX) / 1.5;
        deltaY = (currentY - initialY) / 1.5;
        slidingImage.current.style.transform = `translate3d(${deltaX}px,${deltaY}px,0)`;
      },
      end: () => {
        if (deltaX > threshold) {
          handlePrev();
        } else if (deltaX < -threshold) {
          handleNext();
        } else {
          slidingImage.current.style.transform = `translate3d(0,0,0)`;
          deltaX = null;
          initialX = null;
          currentX = null;
          deltaY = null;
          initialY = null;
          currentY = null;
        }
      },
    };
  };

  useEffect(() => {
    const handleSlide = slider();
    if (slidingImage.current !== null) {
      slidingImage.current.removeEventListener("touchstart", handleSlide.start);
      slidingImage.current.removeEventListener("touchmove", handleSlide.move);
      slidingImage.current.removeEventListener("touchend", handleSlide.end);
      slidingImage.current.addEventListener("touchstart", handleSlide.start);
      slidingImage.current.addEventListener("touchmove", handleSlide.move);
      slidingImage.current.addEventListener("touchend", handleSlide.end);
    }
  }, []);

  return (
    <motion.img
      key={`thumb-${image.id}`}
      ref={slidingImage}
      variants={imageVariants}
      initial="initial"
      draggable="false"
      animate={!imageLoaded ? "initial" : "animate"}
      exit="exit"
      className={`rounded-sm max-w-full max-h-full block g-shadow`}
      alt="Gallery"
      src={image.downloadURL}
      onLoad={() => setImageLoaded(true)}
    />
  );
};

export default GalleryImagePhoto;
