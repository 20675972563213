import { Link } from "react-router-dom";
import TopNavMenuToggle from "./TopNavMenuToggle";

const TopNav = () => {
  return (
    <header className="flex break950:hidden basis-[50px] grow-0 shrink-0 border-b-[1px] border-b-white px-[70px] bg-lightBackground z-20">
      <TopNavMenuToggle />
      <Link to="/" className="flex-1 flex justify-center items-center">
        <h1 className="text-center text-lg break340:text-2xl font-bold text-black uppercase tracking-[2px] leading-none">
          Alan Burles
        </h1>
      </Link>
    </header>
  );
};

export default TopNav;
