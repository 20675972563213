import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Failed = ({ setStep }) => {
  return (
    <div className="flex-1 flex flex-col justify-center items-center">
      <div className="py-12">
        <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 text-[90px]" />
      </div>
      <p className="text-2xl text-center text-black">Payment Error</p>
      <div className="py-6">
        <p className="text-[rgba(0,0,0,0.5)] text-center">
          Something went wrong.
          <br />
          <br />
          Please try again. If you continue to have problems please contact me at{" "}
          <a href="mailto:info@alanburles.com">info@alanburles.com</a>
        </p>
        <div className="flex justify-center items-center">
          <div className="mt-6 bg-black text-white px-12 py-3 uppercase cursor-pointer" onClick={() => setStep(0)}>
            Try again
          </div>
        </div>
      </div>
    </div>
  );
};

export default Failed;
