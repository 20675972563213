import { AnimatePresence } from "framer-motion";

import { useAppContext } from "../../contexts/AppContext";

import GalleryImageDetails from "./GalleryImageDetails";
import GalleryImagePhoto from "./GalleryImagePhoto";

const GalleryMainArea = ({
  handleNext,
  handlePrev,
  selectedImage,
  showDetails,
  setShowDetails,
}) => {
  const { images } = useAppContext();

  return (
    <div className="flex-1 relative">
      {selectedImage && selectedImage.imageID && (
        <div className="absolute inset-0 py-[4px] px-[20px] break950:px-[60px] flex justify-center items-center overflow-hidden">
          {images.find((item) => item.id === selectedImage.imageID) &&
            images
              .filter((item) => item.id === selectedImage.imageID)
              .map((image) => (
                <div
                  key={image.id}
                  className={`h-full w-full relative flex justify-center items-center`}
                >
                  <AnimatePresence mode="wait">
                    {showDetails && (
                      <GalleryImageDetails
                        key="galleryDetails"
                        image={images.find(
                          (item) => item.id === selectedImage.imageID
                        )}
                        setShowDetails={setShowDetails}
                      />
                    )}
                    {!showDetails && (
                      <GalleryImagePhoto
                        key="galleryImage"
                        image={images.find(
                          (item) => item.id === selectedImage.imageID
                        )}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                      />
                    )}
                  </AnimatePresence>
                </div>
              ))}
        </div>
      )}
    </div>
  );
};

export default GalleryMainArea;
