import { useEffect } from "react";
import GalleryThumbnail from "./GalleryThumbnail";

const GalleryThumbnails = ({
  selectedImage,
  setSelectedImage,
  setShowDetails,
  collection,
}) => {
  // Handle thumbnail scrolling
  useEffect(() => {
    const handleScroll = () => {
      const thumbnailContainers = document.getElementsByClassName("thumbnails");
      if (thumbnailContainers[0]) {
        const contW = thumbnailContainers[0].clientWidth;
        const contOffsetL = thumbnailContainers[0].offsetLeft;
        const scrollPos = thumbnailContainers[0].scrollLeft;
        const img =
          thumbnailContainers[0].childNodes[0].childNodes[
            selectedImage.imageIndex
          ];
        const imgOffsetL = img.offsetLeft;
        const imgPos = imgOffsetL - contOffsetL;
        const imgW = img.clientWidth;
        let inView = true;
        if (scrollPos > imgPos || imgPos + imgW > scrollPos + contW) {
          inView = false;
        }
        if (!inView) {
          if (imgPos + imgW < contW) {
            thumbnailContainers[0].scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          } else {
            thumbnailContainers[0].scrollTo({
              top: 0,
              left: imgPos - contW / 2,
              behavior: "smooth",
            });
          }
        }
      }
    };
    if (collection.collectionImages && collection.collectionImages.length > 0) {
      handleScroll();
    }
  });

  return (
    <div className="px-[20px] break950:px-[60px]">
      <div className="h-full w-full overflow-x-scroll pt-[6px] pb-[16px] thumbnails">
        <div className="flex gap-2">
          {collection.collectionImages &&
            collection.collectionImages.length > 0 &&
            collection.collectionImages.map((image, index) => (
              <GalleryThumbnail
                key={image.id}
                index={index}
                image={image}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                setShowDetails={setShowDetails}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default GalleryThumbnails;
