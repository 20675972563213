import { useEffect, useState } from "react";
import { motion } from "framer-motion";

import { useAppContext } from "../../contexts/AppContext";
import { spring } from "../../utils/framerVariants";

import StageIndicators from "./StageIndicators";
import CheckCart from "./CheckCart";
import Delivery from "./Delivery";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Summary from "./Summary";
import Complete from "./Complete";
import Failed from "./Failed";

const cartContainerVariants = {
  initial: {
    x: "100%",
    transition: spring,
  },
  animate: {
    x: 0,
    transition: spring,
  },
};

const Checkout = () => {
  const { cart, books, images, cartOpen, setCartOpen } = useAppContext();
  const [step, setStep] = useState(0);
  const [orderSubTotal, setorderSubTotal] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [deliveryAddress, setDeliveryAddress] = useState({
    fName: "",
    lName: "",
    address: "",
    city: "",
    postcode: "",
    countryCode: "GB",
    region: "GB",
  });

  const updateDeliveryAddress = (value, key) => {
    if (!key) {
      return;
    }
    setDeliveryAddress((prev) => {
      return { ...prev, [key]: value.trim() };
    });
  };

  // Calculate order and delivery charges total
  useEffect(() => {
    let subTotal = 0;
    cart.forEach((cartItem) => {
      if (cartItem.type === "book") {
        subTotal = (subTotal * 100 + Number(cartItem.price) * 100) / 100;
      } else {
        if (cartItem.options && cartItem.options.length > 0) {
          cartItem.options.forEach((cartItemOption) => {
            subTotal = (subTotal * 100 + Number(cartItemOption.price) * 100) / 100;
          });
        }
      }
    });
    setorderSubTotal(() => subTotal);

    let pf = 0;
    let bookCount = 0;
    let printCount = 0;
    let parcelCount = 0;
    if (deliveryAddress && deliveryAddress.region && cart && cart.length > 0) {
      cart.forEach((cartItem) => {
        if (cartItem.type === "book") {
          bookCount = bookCount + 1;
        } else {
          if (cartItem.options && cartItem.options.length > 0) {
            cartItem.options.forEach((cartItemOption) => {
              printCount = printCount + 1;
            });
          }
        }
      });
    }
    if (bookCount > 0) {
      pf =
        pf +
        bookCount *
          (deliveryAddress.region === "GB"
            ? 0
            : deliveryAddress.region === "EU"
            ? 5
            : deliveryAddress.region === "US"
            ? 15
            : 17);
    }
    if (printCount > 0) {
      parcelCount = Math.ceil(printCount / 5);
      pf =
        pf +
        parcelCount *
          (deliveryAddress.region === "GB"
            ? 0
            : deliveryAddress.region === "EU"
            ? 10
            : deliveryAddress.region === "US"
            ? 10
            : 17);
    }

    setDeliveryFee(pf);
  }, [cart, books, images, step, deliveryAddress]);

  // Close cart when empty if payment not completed or failed
  useEffect(() => {
    if (cart.length === 0 && !(step === 3 || step === 4)) {
      setCartOpen((prev) => false);
    }
  }, [cart, step]);

  // Paypal config
  const initialOptions = {
    "client-id": process.env.REACT_APP_PP_CLIENT_ID,
    currency: "GBP",
    intent: "capture",
  };

  return (
    <motion.div
      className={`fixed inset-y-0 right-0 z-[9997] h-full max-w-[500px] w-full flex flex-col items-stretch justify-start bg-lightBackground`}
      variants={cartContainerVariants}
      initial="initial"
      animate={cartOpen ? "animate" : "initial"}
      exit="initial"
    >
      <StageIndicators step={step} />
      <div className="flex-1 flex flex-col items-stretch justify-start px-6 overflow-y-auto">
        {step === 0 && <CheckCart currentStep={step} orderSubTotal={orderSubTotal} setStep={setStep} />}
        {step === 1 && (
          <Delivery
            currentStep={step}
            setStep={setStep}
            deliveryAddress={deliveryAddress}
            updateDeliveryAddress={updateDeliveryAddress}
          />
        )}
        {step === 2 && (
          <PayPalScriptProvider options={initialOptions}>
            <Summary
              orderSubTotal={orderSubTotal}
              currentStep={step}
              setStep={setStep}
              deliveryAddress={deliveryAddress}
              deliveryFee={deliveryFee}
            />
          </PayPalScriptProvider>
        )}
        {step === 3 && <Complete />}
        {step === 4 && <Failed setStep={setStep} />}
      </div>
    </motion.div>
  );
};

export default Checkout;
