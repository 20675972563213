import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

import { useAppContext } from "../../contexts/AppContext";
import GalleryMainArea from "./GalleryMainArea";
import GalleryControls from "./GalleryControls";
import GalleryThumbnails from "./GalleryThumbnails";
import BuyButton from "../BuyButton";

const Gallery = ({ collection }) => {
  const { images, isOnline, cartOpen } = useAppContext();
  const [selectedImage, setSelectedImage] = useState({
    imageIndex: 0,
    imageID: collection.collectionImages[0]?.id,
  });
  const [showDetails, setShowDetails] = useState(false);

  const handlePrev = () => {
    setShowDetails(false);
    setSelectedImage((current) => {
      let newIndex, imageID;
      if (current.imageIndex <= 0) {
        newIndex = collection.collectionImages.length - 1;
      } else {
        newIndex = current.imageIndex - 1;
      }
      imageID = collection.collectionImages[newIndex].id;
      return {
        imageIndex: newIndex,
        imageID: imageID,
      };
    });
  };

  const handleNext = () => {
    setShowDetails(false);
    setSelectedImage((current) => {
      let newIndex, imageID;
      if (current.imageIndex >= collection.collectionImages.length - 1) {
        newIndex = 0;
      } else {
        newIndex = current.imageIndex + 1;
      }
      imageID = collection.collectionImages[newIndex].id;
      return {
        imageIndex: newIndex,
        imageID: imageID,
      };
    });
  };

  const openDetails = () => {
    setShowDetails(true);
  };

  return (
    <>
      <div className="hidden break950:flex grow-0 shrink-0 w-full justify-end items-center px-[20px] break950:px-[60px] pt-[16px] pb-[8px]">
        <div className="h-[36px] w-[1px]" />
        <AnimatePresence>
          {isOnline &&
            images.find((item) => item.id === selectedImage.imageID) &&
            images.find((item) => item.id === selectedImage.imageID && item.forSale) &&
            !showDetails && (
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    type: "spring",
                    stiffness: 600,
                    damping: 50,
                    delay: 0.3,
                    // delay: isInitial ? 0.3 : 0,
                  },
                }}
              >
                <BuyButton action={openDetails} type="secondary" icon={faShoppingCart} text="buy" />
              </motion.div>
            )}
        </AnimatePresence>
      </div>

      <div className={`flex-1 flex flex-col items-stretch`}>
        {/* Image and Caption */}
        <GalleryMainArea
          handleNext={handleNext}
          handlePrev={handlePrev}
          selectedImage={selectedImage}
          showDetails={showDetails}
          setShowDetails={setShowDetails}
        />
        <motion.div initial="initial" animate="animate" className={`flex flex-col`}>
          {/* Controls */}
          <GalleryControls
            handleNext={handleNext}
            handlePrev={handlePrev}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
            selectedImage={selectedImage}
            cartOpen={cartOpen}
          />
          {/* Thumbnails */}
          <GalleryThumbnails
            setSelectedImage={setSelectedImage}
            selectedImage={selectedImage}
            setShowDetails={setShowDetails}
            collection={collection}
          />
        </motion.div>
      </div>
    </>
  );
};

export default Gallery;
