import { useLocation } from "react-router-dom";

import { useAppContext } from "../../contexts/AppContext";
import { useNavigationContext } from "../../contexts/NavigationContext";
import CartCounter from "../cart/CartCounter";

const SideNavCartToggle = () => {
  const { setCartOpen } = useAppContext();
  const { closeNav } = useNavigationContext();
  const { pathname } = useLocation();

  const handleShoppingCart = () => {
    closeNav();
    setCartOpen(true);
  };

  return (
    <li className={`relative pl-[20px] last:pb-6 `}>
      <span
        className={`block relative pr-[44px] pl-[10px] cursor-pointer ${
          pathname === "/"
            ? "text-black break950:text-lightBackground rounded-[1px] break950:hover:bg-[rgba(255,255,255,.3)]"
            : "text-accentColour break950:text-accentColour"
        }`}
        onClick={handleShoppingCart}
      >
        Shopping Cart
        <div className="h-full w-[44px] flex justify-center items-center absolute right-0 inset-y-0">
          {pathname !== "/" && <CartCounter small={false} />}
        </div>
      </span>
    </li>
  );
};

export default SideNavCartToggle;
