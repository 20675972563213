import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import useSize from "../../utils/useSize";

const NotificationItem = ({ item, closeNotification }) => {
  const { width } = useSize();

  return item ? (
    <motion.div
      className="bg-lightBackground rounded-sm overflow-hidden h-shadow shrink-0"
      layout
      initial={{ opacity: 0, y: 50, scale: 0.3 }}
      animate={
        width < 950
          ? { opacity: 1, y: 0, scale: 1 }
          : { opacity: 0.9, y: 0, scale: 1 }
      }
      whileHover={width < 950 ? {} : { opacity: 1, scale: 1.05 }}
      exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
      onClick={closeNotification}
    >
      <Link
        className="flex justify-center items-stretch p-[4px]"
        to={item.link}
      >
        {/* Image - if image */}
        {item.imgLink && (
          <div className="w-[50px] flex justify-center items-start py-1">
            <div
              className={`h-[40px] w-[40px] relative ${
                item.type === "book"
                  ? ""
                  : "rounded-full overflow-hidden bg-white border-2 border-white g-shadow"
              } `}
            >
              <img
                src={item.imgLink}
                alt="notification-item"
                className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${
                  item.type === "book"
                    ? "h-full w-auto max-w-none max-h-none min-w-0 min-h-0"
                    : "min-w-full min-h-full max-w-[160%]"
                }`}
              />
            </div>
          </div>
        )}
        {/* Text */}
        <div className="flex-1 flex flex-col px-[4px] justify-center items-start">
          <h3 className="text-[14px] leading-none">
            {item.type === "book" ? "Now Available" : "Gallery Updated"}
          </h3>
          <p className="capitalize text-[14px]">
            {item.title.replace(" (Includes UK postage)", "")}
          </p>
        </div>
        {/* Close Button */}
        <div
          className="flex justify-start items-start"
          onClick={closeNotification}
        >
          <div className="h-[22px] w-[22px] flex justify-center items-center cursor-pointer">
            <span className="block w-[16px] h-[2px] rounded-[1px] bg-black rotate-45">
              <span className="block w-full h-full rounded-[1px] bg-black rotate-90" />
            </span>
          </div>
        </div>
      </Link>
    </motion.div>
  ) : null;
};

export default NotificationItem;
