import { motion } from "framer-motion";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppContext } from "../../contexts/AppContext";
import useSize from "../../utils/useSize";
import { spring } from "../../utils/framerVariants";
import CartCounter from "./CartCounter";

const CartToggle = () => {
  const { setCartOpen, isOnline } = useAppContext();
  const { width } = useSize();

  const cartToggleVariants = {
    initial: {
      x: 100,
      transition: spring,
    },
    animate: {
      x: 0,
      transition: spring,
    },
  };

  return (
    <motion.div
      className="fixed top-0 right-[12px] break950:right-[52px] z-[59]"
      variants={cartToggleVariants}
      initial="initial"
      animate={width < 950 && isOnline ? "animate" : "initial"}
      exit="initial"
    >
      <div
        className={`relative h-[50px] w-[44px] rounded-full flex justify-center items-center cursor-pointer`}
        onClick={() => setCartOpen(true)}
      >
        <FontAwesomeIcon className="text-[20px]" icon={faShoppingCart} />
        <span className="absolute top-[7px] right-[4px] w-[16px] h-[16px]">
          <CartCounter small={true} />
        </span>
      </div>
    </motion.div>
  );
};

export default CartToggle;
