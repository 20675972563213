import { useAppContext } from "../../contexts/AppContext";
import {
  faPlus,
  faShoppingCart,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";

import { spring } from "../../utils/framerVariants";

import BuyButton from "../BuyButton";

const AboutBookItem = ({ book }) => {
  const { cart, toggleCartItem, isOnline, setCartOpen } = useAppContext();

  const handleCartToggle = () => {
    toggleCartItem({ ...book, type: "book" });
  };

  return (
    <div
      key={book.id}
      className="flex flex-wrap justify-center bg-white rounded g-shadow overflow-hidden"
    >
      <div className="basis-1/3 grow shrink max-w-[350px]">
        <div className="w-full pt-[100%] relative">
          <div className="absolute inset-0 w-full h-full flex justify-center items-center rounded">
            <img
              src={book.imgLink}
              alt="Book"
              className="max-h-full max-w-full"
            />
          </div>
        </div>
      </div>
      <div className="basis-[350px] grow shrink flex flex-col p-6">
        <h3 className="text-xl">{book.title}</h3>
        <div className="bg-accentColour h-[3px] w-[50px] mt-[4px] mb-[10px] rounded-[1px]"></div>
        <p className="capitalize">By {book.author}</p>
        {book.info && book.info !== "" && (
          <div
            className="mt-6 border-[1px] border-[rgba(0,0,0,.2)] rounded p-[10px] text-sm"
            dangerouslySetInnerHTML={{
              __html: book.info.replace(/(?:\r\n|\r|\n)/g, "<br />"),
            }}
          />
        )}

        <div className="mt-3 flex flex-col justify-start items-end gap-3">
          <h3 className="text-[32px] leading-none mb-1">£{book.price}</h3>
          <AnimatePresence>
            {isOnline &&
              book.forSale &&
              !cart.find(
                (cartItem) =>
                  cartItem.type === "book" && cartItem.id === book.id
              ) && (
                <div className="mb-[38px]">
                  <BuyButton
                    text="Add to cart"
                    type="secondary"
                    action={handleCartToggle}
                    icon={faPlus}
                  />
                </div>
              )}
            {isOnline &&
              cart.find(
                (cartItem) =>
                  cartItem.type === "book" && cartItem.id === book.id
              ) && (
                <motion.div
                  className="flex flex-col justify-start items-end"
                  initial={{ x: 300 }}
                  animate={{
                    x: 0,
                    transition: spring,
                  }}
                  exit={false}
                >
                  <BuyButton
                    text="Checkout"
                    type="primary"
                    action={() => setCartOpen(true)}
                    icon={faShoppingCart}
                  />
                  <BuyButton
                    text={"Remove"}
                    action={handleCartToggle}
                    icon={faTimes}
                  />
                </motion.div>
              )}
          </AnimatePresence>
          {!book.forSale && <p>Not in stock</p>}
        </div>
      </div>
    </div>
  );
};

export default AboutBookItem;
