import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppContext } from "../../contexts/AppContext";

const Complete = () => {
  const { setCartOpen } = useAppContext();

  return (
    <div className="flex-1 flex flex-col justify-center items-center">
      <div className="py-12">
        <FontAwesomeIcon icon={faCheckCircle} className="text-green-600 text-[90px]" />
      </div>
      <p className="text-2xl text-center text-black">Payment Complete</p>
      <div className="py-6">
        <p className="text-[rgba(0,0,0,0.5)] text-center">
          Thank you for your order.
          <br />
          <br />
          The order will be with you soon. Any questions please contact me at{" "}
          <a href="mailto:info@alanburles.com">info@alanburles.com</a>
        </p>
        <div className="flex justify-center items-center">
          <div
            className="mt-6 bg-black text-white px-12 py-3 uppercase cursor-pointer"
            onClick={() => setCartOpen(false)}
          >
            Close
          </div>
        </div>
      </div>
    </div>
  );
};

export default Complete;
