export const postageOptions = [
  { region: "int", country: "ALBANIA", code: "AL" },
  { region: "int", country: "ALGERIA", code: "DZ" },
  { region: "int", country: "ANDORRA", code: "AD" },
  { region: "int", country: "ANGOLA", code: "AO" },
  { region: "int", country: "ANGUILLA", code: "AI" },
  { region: "int", country: "ANTIGUA & BARBUDA", code: "AG" },
  { region: "int", country: "ARGENTINA", code: "AR" },
  { region: "int", country: "ARMENIA", code: "AM" },
  { region: "int", country: "ARUBA", code: "AW" },
  { region: "int", country: "AUSTRALIA", code: "AU" },
  { region: "EU", country: "AUSTRIA", code: "AT" },
  { region: "int", country: "AZERBAIJAN", code: "AZ" },
  { region: "int", country: "BAHAMAS", code: "BS" },
  { region: "int", country: "BAHRAIN", code: "BH" },
  { region: "int", country: "BARBADOS", code: "BB" },
  { region: "int", country: "BELARUS", code: "BY" },
  { region: "EU", country: "BELGIUM", code: "BE" },
  { region: "int", country: "BELIZE", code: "BZ" },
  { region: "int", country: "BENIN", code: "BJ" },
  { region: "int", country: "BERMUDA", code: "BM" },
  { region: "int", country: "BHUTAN", code: "BT" },
  { region: "int", country: "BOLIVIA", code: "BO" },
  { region: "int", country: "BOSNIA & HERZEGOVINA", code: "BA" },
  { region: "int", country: "BOTSWANA", code: "BW" },
  { region: "int", country: "BRAZIL", code: "BR" },
  { region: "int", country: "BRITISH VIRGIN ISLANDS", code: "VG" },
  { region: "int", country: "BRUNEI", code: "BN" },
  { region: "EU", country: "BULGARIA", code: "BG" },
  { region: "int", country: "BURKINA FASO", code: "BF" },
  { region: "int", country: "BURUNDI", code: "BI" },
  { region: "int", country: "CAMBODIA", code: "KH" },
  { region: "int", country: "CAMEROON", code: "CM" },
  { region: "int", country: "CANADA", code: "CA" },
  { region: "int", country: "CAPE VERDE", code: "CV" },
  { region: "int", country: "CAYMAN ISLANDS", code: "KY" },
  { region: "int", country: "CHAD", code: "TD" },
  { region: "int", country: "CHILE", code: "CL" },
  { region: "int", country: "CHINA", code: "C2" },
  { region: "int", country: "COLOMBIA", code: "CO" },
  { region: "int", country: "COMOROS", code: "KM" },
  { region: "int", country: "CONGO - BRAZZAVILLE", code: "CG" },
  { region: "int", country: "CONGO - KINSHASA", code: "CD" },
  { region: "int", country: "COOK ISLANDS", code: "CK" },
  { region: "int", country: "COSTA RICA", code: "CR" },
  { region: "int", country: "CÔTE D’IVOIRE", code: "CI" },
  { region: "EU", country: "CROATIA", code: "HR" },
  { region: "EU", country: "CYPRUS", code: "CY" },
  { region: "EU", country: "CZECH REPUBLIC", code: "CZ" },
  { region: "EU", country: "DENMARK", code: "DK" },
  { region: "int", country: "DJIBOUTI", code: "DJ" },
  { region: "int", country: "DOMINICA", code: "DM" },
  { region: "int", country: "DOMINICAN REPUBLIC", code: "DO" },
  { region: "int", country: "ECUADOR", code: "EC" },
  { region: "int", country: "EGYPT", code: "EG" },
  { region: "int", country: "EL SALVADOR", code: "SV" },
  { region: "int", country: "ERITREA", code: "ER" },
  { region: "EU", country: "ESTONIA", code: "EE" },
  { region: "int", country: "ETHIOPIA", code: "ET" },
  { region: "int", country: "FALKLAND ISLANDS", code: "FK" },
  { region: "int", country: "FAROE ISLANDS", code: "FO" },
  { region: "int", country: "FIJI", code: "FJ" },
  { region: "EU", country: "FINLAND", code: "FI" },
  { region: "EU", country: "FRANCE", code: "FR" },
  { region: "int", country: "FRENCH GUIANA", code: "GF" },
  { region: "int", country: "FRENCH POLYNESIA", code: "PF" },
  { region: "int", country: "GABON", code: "GA" },
  { region: "int", country: "GAMBIA", code: "GM" },
  { region: "int", country: "GEORGIA", code: "GE" },
  { region: "EU", country: "GERMANY", code: "DE" },
  { region: "int", country: "GIBRALTAR", code: "GI" },
  { region: "EU", country: "GREECE", code: "GR" },
  { region: "int", country: "GREENLAND", code: "GL" },
  { region: "int", country: "GRENADA", code: "GD" },
  { region: "int", country: "GUADELOUPE", code: "GP" },
  { region: "int", country: "GUATEMALA", code: "GT" },
  { region: "int", country: "GUINEA", code: "GN" },
  { region: "int", country: "GUINEA-BISSAU", code: "GW" },
  { region: "int", country: "GUYANA", code: "GY" },
  { region: "int", country: "HONDURAS", code: "HN" },
  { region: "int", country: "HONG KONG SAR CHINA", code: "HK" },
  { region: "EU", country: "HUNGARY", code: "HU" },
  { region: "int", country: "ICELAND", code: "IS" },
  { region: "int", country: "INDIA", code: "IN" },
  { region: "int", country: "INDONESIA", code: "ID" },
  { region: "EU", country: "IRELAND", code: "IE" },
  { region: "int", country: "ISRAEL", code: "IL" },
  { region: "EU", country: "ITALY", code: "IT" },
  { region: "int", country: "JAMAICA", code: "JM" },
  { region: "int", country: "JAPAN", code: "JP" },
  { region: "int", country: "JORDAN", code: "JO" },
  { region: "int", country: "KAZAKHSTAN", code: "KZ" },
  { region: "int", country: "KENYA", code: "KE" },
  { region: "int", country: "KIRIBATI", code: "KI" },
  { region: "int", country: "KUWAIT", code: "KW" },
  { region: "int", country: "KYRGYZSTAN", code: "KG" },
  { region: "int", country: "LAOS", code: "LA" },
  { region: "EU", country: "LATVIA", code: "LV" },
  { region: "int", country: "LESOTHO", code: "LS" },
  { region: "int", country: "LIECHTENSTEIN", code: "LI" },
  { region: "EU", country: "LITHUANIA", code: "LT" },
  { region: "EU", country: "LUXEMBOURG", code: "LU" },
  { region: "int", country: "MACEDONIA", code: "MK" },
  { region: "int", country: "MADAGASCAR", code: "MG" },
  { region: "int", country: "MALAWI", code: "MW" },
  { region: "int", country: "MALAYSIA", code: "MY" },
  { region: "int", country: "MALDIVES", code: "MV" },
  { region: "int", country: "MALI", code: "ML" },
  { region: "EU", country: "MALTA", code: "MT" },
  { region: "int", country: "MARSHALL ISLANDS", code: "MH" },
  { region: "int", country: "MARTINIQUE", code: "MQ" },
  { region: "int", country: "MAURITANIA", code: "MR" },
  { region: "int", country: "MAURITIUS", code: "MU" },
  { region: "int", country: "MAYOTTE", code: "YT" },
  { region: "int", country: "MEXICO", code: "MX" },
  { region: "int", country: "MICRONESIA", code: "FM" },
  { region: "int", country: "MOLDOVA", code: "MD" },
  { region: "int", country: "MONACO", code: "MC" },
  { region: "int", country: "MONGOLIA", code: "MN" },
  { region: "int", country: "MONTENEGRO", code: "ME" },
  { region: "int", country: "MONTSERRAT", code: "MS" },
  { region: "int", country: "MOROCCO", code: "MA" },
  { region: "int", country: "MOZAMBIQUE", code: "MZ" },
  { region: "int", country: "NAMIBIA", code: "NA" },
  { region: "int", country: "NAURU", code: "NR" },
  { region: "int", country: "NEPAL", code: "NP" },
  { region: "EU", country: "NETHERLANDS", code: "NL" },
  { region: "int", country: "NEW CALEDONIA", code: "NC" },
  { region: "int", country: "NEW ZEALAND", code: "NZ" },
  { region: "int", country: "NICARAGUA", code: "NI" },
  { region: "int", country: "NIGER", code: "NE" },
  { region: "int", country: "NIGERIA", code: "NG" },
  { region: "int", country: "NIUE", code: "NU" },
  { region: "int", country: "NORFOLK ISLAND", code: "NF" },
  { region: "int", country: "NORWAY", code: "NO" },
  { region: "int", country: "OMAN", code: "OM" },
  { region: "int", country: "PALAU", code: "PW" },
  { region: "int", country: "PANAMA", code: "PA" },
  { region: "int", country: "PAPUA NEW GUINEA", code: "PG" },
  { region: "int", country: "PARAGUAY", code: "PY" },
  { region: "int", country: "PERU", code: "PE" },
  { region: "int", country: "PHILIPPINES", code: "PH" },
  { region: "int", country: "PITCAIRN ISLANDS", code: "PN" },
  { region: "EU", country: "POLAND", code: "PL" },
  { region: "EU", country: "PORTUGAL", code: "PT" },
  { region: "int", country: "QATAR", code: "QA" },
  { region: "int", country: "RÉUNION", code: "RE" },
  { region: "EU", country: "ROMANIA", code: "RO" },
  { region: "int", country: "RUSSIA", code: "RU" },
  { region: "int", country: "RWANDA", code: "RW" },
  { region: "int", country: "SAMOA", code: "WS" },
  { region: "int", country: "SAN MARINO", code: "SM" },
  { region: "int", country: "SÃO TOMÉ & PRÍNCIPE", code: "ST" },
  { region: "int", country: "SAUDI ARABIA", code: "SA" },
  { region: "int", country: "SENEGAL", code: "SN" },
  { region: "int", country: "SERBIA", code: "RS" },
  { region: "int", country: "SEYCHELLES", code: "SC" },
  { region: "int", country: "SIERRA LEONE", code: "SL" },
  { region: "int", country: "SINGAPORE", code: "SG" },
  { region: "EU", country: "SLOVAKIA", code: "SK" },
  { region: "EU", country: "SLOVENIA", code: "SI" },
  { region: "int", country: "SOLOMON ISLANDS", code: "SB" },
  { region: "int", country: "SOMALIA", code: "SO" },
  { region: "int", country: "SOUTH AFRICA", code: "ZA" },
  { region: "int", country: "SOUTH KOREA", code: "KR" },
  { region: "EU", country: "SPAIN", code: "ES" },
  { region: "int", country: "SRI LANKA", code: "LK" },
  { region: "int", country: "ST. HELENA", code: "SH" },
  { region: "int", country: "ST. KITTS & NEVIS", code: "KN" },
  { region: "int", country: "ST. LUCIA", code: "LC" },
  { region: "int", country: "ST. PIERRE & MIQUELON", code: "PM" },
  { region: "int", country: "ST. VINCENT & GRENADINES", code: "VC" },
  { region: "int", country: "SURINAME", code: "SR" },
  { region: "int", country: "SVALBARD & JAN MAYEN", code: "SJ" },
  { region: "int", country: "SWAZILAND", code: "SZ" },
  { region: "EU", country: "SWEDEN", code: "SE" },
  { region: "int", country: "SWITZERLAND", code: "CH" },
  { region: "int", country: "TAIWAN", code: "TW" },
  { region: "int", country: "TAJIKISTAN", code: "TJ" },
  { region: "int", country: "TANZANIA", code: "TZ" },
  { region: "int", country: "THAILAND", code: "TH" },
  { region: "int", country: "TOGO", code: "TG" },
  { region: "int", country: "TONGA", code: "TO" },
  { region: "int", country: "TRINIDAD & TOBAGO", code: "TT" },
  { region: "int", country: "TUNISIA", code: "TN" },
  { region: "int", country: "TURKMENISTAN", code: "TM" },
  { region: "int", country: "TURKS & CAICOS ISLANDS", code: "TC" },
  { region: "int", country: "TUVALU", code: "TV" },
  { region: "int", country: "UGANDA", code: "UG" },
  { region: "int", country: "UKRAINE", code: "UA" },
  { region: "int", country: "UNITED ARAB EMIRATES", code: "AE" },
  { region: "GB", country: "UNITED KINGDOM", code: "GB" },
  { region: "US", country: "UNITED STATES", code: "US" },
  { region: "int", country: "URUGUAY", code: "UY" },
  { region: "int", country: "VANUATU", code: "VU" },
  { region: "int", country: "VATICAN CITY", code: "VA" },
  { region: "int", country: "VENEZUELA", code: "VE" },
  { region: "int", country: "VIETNAM", code: "VN" },
  { region: "int", country: "WALLIS & FUTUNA", code: "WF" },
  { region: "int", country: "YEMEN", code: "YE" },
  { region: "int", country: "ZAMBIA", code: "ZM" },
  { region: "int", country: "ZIMBABWE", code: "ZW" },
];
