import { Link, useLocation } from "react-router-dom";

import { useNavigationContext } from "../../contexts/NavigationContext";

import Loader from "../Loader";

const SideNavListItemSubItem = ({ item, about, parent }) => {
  const { pathname } = useLocation();
  const { activeNav, closeNav } = useNavigationContext();

  return !item ? (
    <Loader type="dark" width="50%" height="10px" opacity={0.5} />
  ) : (
    <li className="block">
      <Link
        to={
          !about
            ? "collections/" + encodeURIComponent(item.name.toLowerCase())
            : item.to
        }
        onClick={activeNav && activeNav.name === parent ? false : closeNav}
        className={`block text-sm py-1 pl-[10px] ${
          pathname === "/"
            ? "text-textGrey break950:text-lightBackground rounded-[1px] break950:hover:bg-[rgba(255,255,255,.3)]"
            : "text-textGrey"
        } ${
          (about && pathname === item.to) ||
          (!about &&
            pathname ===
              `/collections/${encodeURIComponent(item.name.toLowerCase())}`)
            ? "!text-accentColour"
            : ""
        }`}
      >
        {item.name}
      </Link>
    </li>
  );
};

export default SideNavListItemSubItem;
