import { createContext, useContext, useState } from "react";

export const NavigationContext = createContext();

export function useNavigationContext() {
  return useContext(NavigationContext);
}

const NavigationContextProvider = (props) => {
  const [navOpen, setNavOpen] = useState(false);
  const [activeNav, setActiveNav] = useState(null);
  const [collectionCaption, setCollectionCaption] = useState(null);
  const selectNavItem = (x) => setActiveNav((prev) => x);

  const openNav = () => setNavOpen(true);

  const closeNav = () => {
    setNavOpen(false);
    setActiveNav((prev) => null);
  };

  const aboutItems = [
    {
      imgLink: "/images/alan.jpeg",
      name: "biography",
      to: "/biography",
    },
    {
      imgLink: "/images/contact.jpg",
      name: "contact",
      to: "/contact",
    },
  ];

  const values = {
    navOpen,
    openNav,
    closeNav,
    activeNav,
    selectNavItem,
    aboutItems,
    collectionCaption,
    setCollectionCaption,
  };

  return (
    <NavigationContext.Provider value={values}>
      {props.children}
    </NavigationContext.Provider>
  );
};

export default NavigationContextProvider;
