import { useNavigationContext } from "../../contexts/NavigationContext";

const TopNavMenuToggle = () => {
  const { openNav } = useNavigationContext();
  return (
    <div
      onClick={openNav}
      className="fixed top-0 left-[20px] h-[50px] w-[30px] flex justify-center items-center text-[20px] cursor-pointer"
    >
      <span className="block relative w-full h-[3px] bg-black rounded-[1px]">
        <span className="block absolute inset-0 h-full w-full rounded-[1px] bg-black translate-y-[8px]" />
        <span className="block absolute inset-0 h-full w-full rounded-[1px] bg-black -translate-y-[8px]" />
      </span>
    </div>
  );
};

export default TopNavMenuToggle;
