import { motion } from "framer-motion";

const StageIndicators = ({ step }) => {
  return (
    <div className="basis-[60px] shrink-0  w-full px-6">
      <div className="h-[60px] relative flex justify-between items-center">
        {/* Backline */}
        <div className="w-full h-[4px] bg-[rgba(0,0,0,0.2)] inset-x-0 top-1/2 absolute -translate-y-1/2 rounded-[2px] overflow-hidden -z-[1]">
          <motion.div
            className="absolute left-0 h-full bg-black rounded-[2px]"
            style={{ width: step < 3 ? `${(step / 3) * 100}%` : "100%" }}
          ></motion.div>
        </div>
        {Array(4)
          .fill("dot")
          .map((dotItem, index) => (
            <span
              key={`${dotItem}-${index}`}
              className={`block h-[24px] w-[24px] rounded-full border-4 border-lightBackground`}
            >
              <span
                className={`block h-full w-full rounded-full border-2 ${
                  index < step
                    ? "border-black bg-lightBackground"
                    : index === step
                    ? "border-black bg-black"
                    : "bg-lightBackground border-[rgba(0,0,0,0.2)]"
                } `}
              ></span>
            </span>
          ))}
      </div>
    </div>
  );
};

export default StageIndicators;
