import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import { useAppContext } from "../../contexts/AppContext";

const CartCounter = ({ small }) => {
  const { cart } = useAppContext();

  const [cartCount, setCartCount] = useState(null);
  const [throb, setThrob] = useState(false);

  useEffect(() => {
    let count = 0;
    cart.forEach((cartItem) => {
      if (cartItem.type === "book") {
        count = count + 1;
      } else {
        if (cartItem.options && cartItem.options.length > 0) {
          count = count + cartItem.options.length;
        }
      }
    });
    setCartCount((prev) => count);
  }, [cart]);

  useEffect(() => {
    setThrob(true);
    const timer = setTimeout(() => {
      setThrob(false);
    }, [100]);
    return () => {
      clearTimeout(timer);
    };
  }, [cartCount]);

  return (
    <motion.div
      className={`rounded-full bg-accentColour relative ${
        small ? "h-[16px] w-[16px]" : "h-[20px] w-[20px]"
      }`}
      initial={{ scale: 1 }}
      animate={
        throb
          ? {
              scale: 1.3,
              transition: { duration: 0.1 },
            }
          : { scale: 1, transition: { duration: 0.1 } }
      }
    >
      <span
        className={`absolute inset-0 flex justify-center items-center text-white ${
          small ? "text-[10px]" : "text-[12px]"
        }`}
      >
        {cartCount}
      </span>
    </motion.div>
  );
};

export default CartCounter;
