import { motion } from "framer-motion";

const AboutBioLoader = () => {
  return (
    <section className="pt-[20px] px-[20px] break950:px-[60px]">
      <div className="flex gap-6">
        <div className="flex-1 flex flex-col gap-[10px]">
          {[...Array(18)].map((x, i) => (
            <span
              key={i}
              className="block h-[18px] w-full bg-[rgba(0,0,0,.1)] relative"
            >
              <motion.span
                animate={{ x: ["0vw", "100vw"] }}
                transition={{
                  repeat: Infinity,
                  ease: "easeInOut",
                  duration: 3,
                }}
                className="absolute inset-y-0 -left-[100px] block w-[100px] h-full bg-gradient-to-r from-[rgba(255,255,255,0)] via-[rgba(255,255,255,.2)] to-[rgba(255,255,255,0)]"
              ></motion.span>
            </span>
          ))}
        </div>
        <div className="w-[33%] max-w-[300px]">
          <div className="w-full">
            <div className="pt-[130%] bg-[rgba(0,0,0,.1)] rounded"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBioLoader;
