import { motion } from "framer-motion";

import { useNavigationContext } from "../../contexts/NavigationContext";
import { useAppContext } from "../../contexts/AppContext";

import SideNavListItemSubItem from "./SideNavListItemSubItem";

const SideNavListItemSubList = ({ navItem, itemActive }) => {
  const { aboutItems, activeNav } = useNavigationContext();
  const { collections } = useAppContext();

  return (
    <motion.ul
      className="pl-3 overflow-hidden flex flex-col gap-1 items-stretch justify-start"
      initial={{ height: 0 }}
      animate={
        (activeNav && activeNav.name === navItem.name) ||
        (!activeNav && itemActive)
          ? { height: "auto" }
          : { height: 0 }
      }
    >
      {/* List of collection subpages if collections have been received */}
      {navItem.name !== "about" &&
        collections.length > 0 &&
        collections
          .filter(
            (item) =>
              item.group === navItem.name &&
              item.collectionImages &&
              item.collectionImages.length > 0
          )
          .map((collectionItem) => (
            <SideNavListItemSubItem
              key={collectionItem.id}
              item={collectionItem}
              parent={navItem.name}
            />
          ))}
      {/* About subpages */}
      {navItem.name === "about" &&
        aboutItems.map((aboutItem) => (
          <SideNavListItemSubItem
            key={`miniNavAbout${aboutItem.name}`}
            item={aboutItem}
            about={true}
            parent={navItem.name}
          />
        ))}
    </motion.ul>
  );
};

export default SideNavListItemSubList;
