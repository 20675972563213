import StepButtons from "./StepButtons";
import { useState } from "react";
import { postageOptions } from "../../utils/postage";

const Delivery = ({ setStep, currentStep, deliveryAddress, updateDeliveryAddress }) => {
  const [errors, setErrors] = useState({
    type: false,
    fName: false,
    lName: false,
    address: false,
    city: false,
    postcode: false,
    country: false,
  });
  const [showCountrySelector, setShowCountrySelector] = useState(false);

  const handleNextStep = () => {
    let submitErrors = false;
    let errorObj = {
      fName: false,
      lName: false,
      address: false,
      city: false,
      postcode: false,
      country: false,
    };
    setErrors(() => ({ ...errorObj }));
    if (!deliveryAddress.fName || deliveryAddress.fName.trim() === "") {
      submitErrors = true;
      errorObj.fName = true;
    }
    if (!deliveryAddress.lName || deliveryAddress.lName.trim() === "") {
      submitErrors = true;
      errorObj.lName = true;
    }
    if (!deliveryAddress.address || deliveryAddress.address.trim() === "") {
      submitErrors = true;
      errorObj.address = true;
    }
    if (!deliveryAddress.city || deliveryAddress.city.trim() === "") {
      submitErrors = true;
      errorObj.city = true;
    }
    if (!deliveryAddress.postcode || deliveryAddress.postcode.trim() === "") {
      submitErrors = true;
      errorObj.postcode = true;
    }
    if (!deliveryAddress.countryCode) {
      submitErrors = true;
      errorObj.country = true;
    }

    if (!submitErrors) {
      setStep(() => 2);
    } else {
      setErrors(() => ({ ...errorObj }));
    }
  };

  return (
    <>
      <div className="flex-1">
        <p className="text-2xl text-left text-black">Delivery</p>
        <div className="flex flex-col justify-start items-stretch py-6">
          <div className="bg-white p-3 w-full flex flex-col gap-3 items-stretch justify-start b-shadow rounded">
            <div className="flex justify-center items-center gap-3 flex-wrap">
              <div className="basis-[200px] grow shrink flex flex-col">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  autoComplete="off"
                  defaultValue={deliveryAddress.fName}
                  className={`p-2 rounded ${errors.fName ? "bg-[rgba(255,0,0,0.3)]" : "bg-[rgba(0,0,0,0.08)]"}`}
                  onChange={(e) => {
                    setErrors((prev) => ({ ...prev, fName: false }));
                    updateDeliveryAddress(e.target.value, "fName");
                  }}
                />
              </div>
              <div className="basis-[200px] grow shrink flex flex-col">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  autoComplete="off"
                  defaultValue={deliveryAddress.lName}
                  className={`p-2 rounded ${errors.lName ? "bg-[rgba(255,0,0,0.3)]" : "bg-[rgba(0,0,0,0.08)]"}`}
                  onChange={(e) => {
                    setErrors((prev) => ({ ...prev, lName: false }));
                    updateDeliveryAddress(e.target.value, "lName");
                  }}
                />
              </div>
            </div>
            <div className="mt-3 flex flex-col">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                autoComplete="off"
                className={`p-2 rounded ${errors.address ? "bg-[rgba(255,0,0,0.3)]" : "bg-[rgba(0,0,0,0.08)]"}`}
                defaultValue={deliveryAddress.address}
                onChange={(e) => {
                  setErrors((prev) => ({ ...prev, address: false }));
                  updateDeliveryAddress(e.target.value, "address");
                }}
              />
            </div>
            <div className="flex justify-center items-center gap-3 flex-wrap">
              <div className="basis-[200px] grow shrink flex flex-col">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="city"
                  autoComplete="off"
                  className={`p-2 rounded ${errors.city ? "bg-[rgba(255,0,0,0.3)]" : "bg-[rgba(0,0,0,0.08)]"}`}
                  defaultValue={deliveryAddress.city}
                  onChange={(e) => {
                    setErrors((prev) => ({ ...prev, city: false }));
                    updateDeliveryAddress(e.target.value, "city");
                  }}
                />
              </div>
              <div className="basis-[200px] grow shrink flex flex-col">
                <label htmlFor="postcode">Postcode</label>
                <input
                  type="text"
                  id="postcode"
                  autoComplete="off"
                  className={`p-2 rounded ${errors.postcode ? "bg-[rgba(255,0,0,0.3)]" : "bg-[rgba(0,0,0,0.08)]"}`}
                  defaultValue={deliveryAddress.postcode}
                  onChange={(e) => {
                    setErrors((prev) => ({ ...prev, postcode: false }));
                    updateDeliveryAddress(e.target.value, "postcode");
                  }}
                />
              </div>
              <div className="basis-[200px] grow shrink flex flex-col">
                <span>Country</span>
                {!showCountrySelector && (
                  <span
                    id="country"
                    className={`p-2 rounded h-[40px] ${
                      errors.country ? "bg-[rgba(255,0,0,0.3)]" : "bg-[rgba(0,0,0,0.08)]"
                    }`}
                    onClick={() => {
                      setErrors((prev) => ({ ...prev, country: false }));
                      setShowCountrySelector(true);
                    }}
                  >
                    {deliveryAddress.countryCode !== ""
                      ? postageOptions.find((item) => item.code === deliveryAddress.countryCode)?.country
                      : ""}
                  </span>
                )}
                {showCountrySelector && (
                  <div className="rounded h-[200px] overflow-y-scroll bg-[rgba(0,0,0,0.08)]">
                    <div className="flex-1 flex flex-col justify-start items-stretch overflow-y-scroll">
                      {postageOptions
                        .filter((item) => item.code === deliveryAddress.countryCode)
                        .map((item) => (
                          <p
                            key={item.code}
                            className="cursor-pointer uppercase text-black pt-2 px-2"
                            onClick={() => {
                              updateDeliveryAddress(item.code, "countryCode");
                              updateDeliveryAddress(item.region, "region");
                              setShowCountrySelector(false);
                            }}
                          >
                            {item.country}
                          </p>
                        ))}
                      {postageOptions.map((item) => (
                        <p
                          key={item.code}
                          className="cursor-pointer uppercase pt-2 px-2 last:pb-2"
                          onClick={() => {
                            updateDeliveryAddress(item.code, "countryCode");
                            updateDeliveryAddress(item.region, "region");
                            setShowCountrySelector(false);
                          }}
                        >
                          {item.country}
                        </p>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <StepButtons title="Summary" nextAction={handleNextStep} setStep={setStep} currentStep={currentStep} />
    </>
  );
};

export default Delivery;
