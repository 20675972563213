import { useNavigationContext } from "../../contexts/NavigationContext";

const NavUnderlay = () => {
  const { closeNav, navOpen, selectNavItem } = useNavigationContext();

  return navOpen ? (
    <div
      className={`block break950:hidden fixed inset-0 bg-[rgba(0,0,0,0.8)] z-[60]`}
      onMouseEnter={() => selectNavItem(null)}
      onClick={closeNav}
    />
  ) : null;
};

export default NavUnderlay;
