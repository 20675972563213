import { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

const AboutBioFeatureItem = ({ scrollContainerRef, feature }) => {
  const scrollRef = useRef(null);
  const { scrollYProgress } = useScroll({
    layoutEffect: false,
    container: scrollContainerRef,
    target: scrollRef,
    offset: ["0 1", "2 1"],
  });

  const pos = useTransform(scrollYProgress, [0, 1], [100, 0]);
  // const scale = useTransform(scrollYProgress, [0, 1], [0.7, 1]);
  const opacity = useTransform(scrollYProgress, [0, 1], [0, 1]);
  const rotate = useTransform(scrollYProgress, [0, 1], ["4deg", "-6deg"]);

  return (
    <motion.a
      href={feature.featureLink}
      target="_blank"
      rel="noreferrer"
      ref={scrollRef}
      className="basis-[600px] grow shrink bg-gradient-to-r from-white to-[rgba(161,223,255,.2)] flex flex-wrap justify-center overflow-hidden rounded h-shadow group cursor-pointer"
      style={{ opacity: opacity, x: pos }}
    >
      {/* Text section */}
      <div className="flex-1 py-3 pl-3 pr-6 flex flex-col">
        <h3 className="text-[14px] break550:text-[18px]">{feature.name}</h3>
        <div className="bg-accentColour h-[3px] w-[50px] mt-[4px] mb-[10px] rounded-[1px]"></div>
        <span className="text-[12px] break550:text-base font-semibold">
          {feature.publication}
        </span>
        <p className="text-[10px] break550:text-[12px]">{feature.date}</p>
      </div>
      {/* Image section */}
      <div className="min-h-[100px] basis-1/3 pt-3 pr-3">
        <motion.div
          className="relative h-full w-full bg-white rounded translate-y-1 origin-bottom-right"
          style={{
            rotate: rotate,
          }}
        >
          <img
            src={feature.imgPath}
            className="absolute inset-x-0 top-0 min-h-full w-full rounded"
            alt="Feature Link"
          />
        </motion.div>
      </div>
    </motion.a>
  );
};

export default AboutBioFeatureItem;
