import { useAppContext } from "../../contexts/AppContext";

const StepButtons = ({ title, nextAction, currentStep, setStep }) => {
  const { setCartOpen } = useAppContext();

  const handleBack = () => {
    setStep((prev) => prev - 1);
  };
  return (
    <div className="shrink-0 flex justify-end items-center gap-6 no_select pb-6">
      {currentStep !== 0 && (
        <p onClick={handleBack} className="uppercase cursor-pointer text-black">
          Back
        </p>
      )}
      {currentStep === 0 && (
        <p onClick={() => setCartOpen(false)} className="uppercase cursor-pointer text-black">
          Close
        </p>
      )}
      {currentStep < 2 && (
        <div
          onClick={nextAction}
          className="px-4 py-2 text-white rounded uppercase cursor-pointer text-center bg-accentColour"
        >
          <p className="text-white">Next Step: {title}</p>
        </div>
      )}
    </div>
  );
};

export default StepButtons;
