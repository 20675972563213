export const pageVariants = {
  initial: {
    x: "-100%",
    transition: {
      ease: "easeInOut",
      duration: 0.3,
    },
  },
  animate: {
    x: "0%",
    transition: {
      ease: "easeInOut",
      duration: 0.3,
    },
  },
  exit: {
    x: "100%",
    transition: {
      ease: "easeInOut",
      duration: 0.3,
    },
  },
};

export const spring = {
  type: "spring",
  stiffness: 800,
  damping: 50,
};
